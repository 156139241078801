import { Select } from "antd";
import React, { useEffect, useState } from "react";
import StaffApi from "../../api/StaffApi";
import { Staff } from "../../type/StaffType";

const StaffSelect:React.FC<any> = (props)=>{

    const [options, setOptions] = useState<[]>([]);
    
    useEffect(()=>{
        StaffApi.findStaffList({
            limit: 1000,
            deletedFlag: false,
            ...(props.params||{})
        }).then((result)=>{
            const newOptions = result.map((item:Staff)=>{
                return {
                    key: item.id,
                    value: item.id,
                    label: item.name,
                }
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <Select
            options={options}
            allowClear={true}
            style={{width:'100%'}}
            popupMatchSelectWidth={false}
            showSearch={true}
            filterOption={(inputValue, option)=>{
                return (option?.label??"").toString().indexOf(inputValue)>=0;
            }}
            {...props}
        />
    );
}

export default StaffSelect;