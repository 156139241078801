import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber, Radio } from "antd";
import React, { useEffect, useState } from "react";
import ProductApi from "../../api/ProductApi";
import { Product, ProductDetailProps } from "../../type/ProductType";
import ProductModelApi from "../../api/ProductModelApi";
import HttpInvoker from "../../../../utils/http/HttpInvoker";
import ProductPropApi from "../../api/ProductPropApi";

const ProductDetail:React.FC<ProductDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
    };

    const [title, setTitle] = useState<string>(props?.product?.name);
    const [edited, setEdited] = useState<boolean>(false);
    const [product, setProduct] = useState<Product|undefined>(props?.product);
    const [feeInfo, setFeeInfo] = useState<any>();
    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Drawer
            title={title + "-详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {...props}
        >
            <ProDescriptions<Product>
                column={2}
                size={'middle'}
                request={async()=>{
                    const result:Product = await ProductApi.findProductById(props.product?.id);
                    setTitle(result?.name);
                    setProduct(result);

                    return {
                        success: true,
                        data: result,
                    }
                }}
                editable={{
                    onSave: async (keyPath: any, newData:any, oldData:any)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const product = await ProductApi.patchEditProduct(requestData);
                        setTitle(product?.name);
                        setEdited(true);
                    }
                }}
            >
                <ProDescriptions.Item
                    label="设备类别"
                    labelStyle={labelStyle}
                    dataIndex={['deviceType']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="模型ID"
                    labelStyle={labelStyle}
                    dataIndex={['modelId']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="类别ID"
                    labelStyle={labelStyle}
                    dataIndex={['categoryId']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="安装区域代码"
                    labelStyle={labelStyle}
                    dataIndex={['installAreaCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="安装地址"
                    labelStyle={labelStyle}
                    dataIndex={['installAddress']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="使用单位"
                    labelStyle={labelStyle}
                    dataIndex={['useCompany']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="安装单位"
                    labelStyle={labelStyle}
                    dataIndex={['installCompany']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="检验类型"
                    labelStyle={labelStyle}
                    dataIndex={['checkType']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="下次检验日期"
                    labelStyle={labelStyle}
                    dataIndex={['nextCheckDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="最近检验日期"
                    labelStyle={labelStyle}
                    dataIndex={['lastCheckDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="最近重大修理日期"
                    labelStyle={labelStyle}
                    dataIndex={['lastRepairDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="最近改造日期"
                    labelStyle={labelStyle}
                    dataIndex={['lastReformDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="设备启用日期"
                    labelStyle={labelStyle}
                    dataIndex={['installDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="设备启用日期"
                    labelStyle={labelStyle}
                    dataIndex={['useBeginDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="任务状态"
                    labelStyle={labelStyle}
                    dataIndex={['taskStatus']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="任务单编号"
                    labelStyle={labelStyle}
                    dataIndex={['taskCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="设备代码"
                    labelStyle={labelStyle}
                    dataIndex={['code']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="产品名称"
                    labelStyle={labelStyle}
                    dataIndex={['name']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
            </ProDescriptions>


            <ProDescriptions 
                title="设备额外属性" 
                column={4}
                editable={{
                    onSave: async (keyPath: any, newData:any, oldData:any)=>{
                        for(let key in newData) {
                            await ProductPropApi.patchEditProductProp({
                                id: key,
                                value: newData[key],
                            });
                            return true;
                        }

                        return false;
                    }
                }}
            >
                {(product?.propList||[]).map((prop:any)=>{
                        return (
                            <ProDescriptions.Item
                                label={prop?.modelProp?.label}
                                labelStyle={labelStyle}
                                dataIndex={[prop?.id]}
                                valueType="text"
                                copyable={false}
                                render={(dom:any, entity:any, index:any, action:any) => {
                                    return (
                                        <div>
                                            {prop?.value}
                                        </div>
                                    );
                                }}
                                renderFormItem={() => {
                                    return <Input />;
                                }}
                            />
                        );
                })}
            </ProDescriptions>


            <Descriptions title="设备费用明细" column={4} 
            
            extra={
                <Radio.Group onChange={(e)=>{
                    HttpInvoker.getObject(`/api/tenant/crm/product/calculator`, {
                        productId: product?.id,
                        checkKind: e.target.value,
                    }).then((res)=>{
                        setFeeInfo(res);
                    });
                }}>
                    {
                        (product?.deviceType=='电梯') &&
                        [
                            <Radio.Button value="定期检验">定期检验</Radio.Button>,
                            <Radio.Button value="安装检验">安装检验</Radio.Button>,
                            <Radio.Button value="改造检验">改造检验</Radio.Button>,
                            <Radio.Button value="重大修理检验">重大修理检验</Radio.Button>
                        ]
                    }
                    {
                        (product?.deviceType=='起重机械') &&
                        [
                            <Radio.Button value="定期检验">定期检验</Radio.Button>,
                            <Radio.Button value="首次检验">首次检验</Radio.Button>,
                            <Radio.Button value="改造监检">改造监检</Radio.Button>,
                            <Radio.Button value="重大修理监检">重大修理监检</Radio.Button>,
                            <Radio.Button value="安装监检">安装监检</Radio.Button>
                        ]
                    }
                </Radio.Group>
            }>
                {(feeInfo?.lineList||[]).map((prop:any)=>{
                        return (
                            <Descriptions.Item label={prop?.label}>
                                {prop?.amount}
                            </Descriptions.Item>
                        );
                })}
                <Descriptions.Item label={<strong>合计</strong>}>
                    {feeInfo?.totalAmount}
                </Descriptions.Item>
            </Descriptions>
        </Drawer>
    );
}

export default ProductDetail;