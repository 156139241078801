
const FormItemLayout = {

    span24: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    },

    span12: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    },

    span8: {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
    },

    span6: {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },     
    },

    span4: {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
    },
};

export default {
    itemLayout: FormItemLayout,
};