
import {Product, ProductQto} from './../type/ProductType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProduct = async (params:Product)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product`, params);
};


const editProduct = async (params:Product)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product/${params.id}`, params);
};


const patchEditProduct = async (params:Product)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product/${params.id}`, params);
};


const deleteProduct = async (params:Product)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product/${params.id}`, params);
};


const findProductById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product/${params}`, params);
};

const findProductList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product/list`, params);
};

const findProductPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product/page`, params);
};



export default {
    addProduct,
    editProduct,
    patchEditProduct,
    deleteProduct,
    findProductById,
    findProductList,
    findProductPage,
};


