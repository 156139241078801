import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber, Radio, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import SalesOrderApi from "../../api/SalesOrderApi";
import PaymentApi from "../../api/PaymentApi";
import ProductReportApi from "../../api/ProductReportApi";
import InvoiceApi from "../../api/InvoiceApi";
import { SalesOrder, SalesOrderDetailProps } from "../../type/SalesOrderType";
import PaymentConsumeLineApi from "../../api/PaymentConsumeLineApi";
import StaffSelect from "@/modules/system/pages/Staff/StaffSelector";
import { ProFormDatePicker } from "@ant-design/pro-components";

const SalesOrderDetail:React.FC<SalesOrderDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
    };

    const [salesOrder, setSalesOrder] = useState(props.salesOrder);
    const [reportList, setReportList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [edited, setEdited] = useState<boolean>(false);
    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Drawer
            title={"任务单详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Space>
                    <Button
                        onClick={()=>{
                            window.open(`/api/tenant/crm/sales-order/print_inspection/${salesOrder.id}?Authorization=${localStorage.getItem('Authorization')}`);
                        }}
                    >
                        打印报检单
                    </Button>
                    <Button
                        onClick={()=>{
                            window.open(`/api/tenant/crm/sales-order/print_price/${salesOrder.id}?Authorization=${localStorage.getItem('Authorization')}`);
                        }}
                    >
                        打印价格明细
                    </Button>
                    <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                    >
                        关闭
                    </Button>
                </Space>
            ]}
            {...props}
        >
            <ProDescriptions<SalesOrder>
                column={2}
                size={'middle'}
                request={async()=>{
                    const result:SalesOrder = await SalesOrderApi.findSalesOrderById(props.salesOrder?.id);
                    setSalesOrder(result);

                    const reportList = await ProductReportApi.findProductReportList({
                        refSalesOrderId: result.id,
                        limit: 1000,
                        deletedFlag:false,
                    });
                    setReportList(reportList);

                    const paymentList = await PaymentConsumeLineApi.findPaymentConsumeLineList({
                        refSalesOrderId: result.id,
                        limit: 1000,
                        deletedFlag:false,
                    });
                    setPaymentList(paymentList);

                    if(result?.invoiceSn){
                        const invoiceList = await InvoiceApi.findInvoiceList({
                            invoiceSn: result?.invoiceSn.split(","),
                        });
                        setInvoiceList(invoiceList);
                    }

                    return {
                        success: true,
                        data: result,
                    }
                }}
                editable={{
                    onSave: async (keyPath: any, newData:any, oldData:any)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const salesOrder = await SalesOrderApi.patchEditSalesOrder(requestData);
                        setSalesOrder(salesOrder);
                        setEdited(true);
                    }
                }}
            >
                <ProDescriptions.Item
                    label="任务单号"
                    labelStyle={labelStyle}
                    dataIndex={['taskNo']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />

                <ProDescriptions.Item
                    label="检验日期"
                    labelStyle={labelStyle}
                    dataIndex={['checkDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <ProFormDatePicker />;
                    }}
                />
                
                <ProDescriptions.Item editable={false} labelStyle={{display:'none'}} span={2}>
                    <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8}}>
                        收费项目
                    </div>
                </ProDescriptions.Item>

                <ProDescriptions.Item
                    label="报检类型"
                    labelStyle={labelStyle}
                    dataIndex={['businessType']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return (
                            <Select>
                                <Select.Option value="定期检验">定期检验</Select.Option>,
                                {
                                    (salesOrder?.orderType=='电梯') &&
                                    [
                                        <Select.Option value="安装检验">安装检验</Select.Option>,
                                        <Select.Option value="改造检验">改造检验</Select.Option>,
                                        <Select.Option value="重大修理检验">重大修理检验</Select.Option>
                                    ]
                                }
                                {
                                    (salesOrder?.orderType=='起重机械') &&
                                    [
                                        <Select.Option value="首次检验">首次检验</Select.Option>,
                                        <Select.Option value="改造监检">改造监检</Select.Option>,
                                        <Select.Option value="重大修理监检">重大修理监检</Select.Option>,
                                        <Select.Option value="安装监检">安装监检</Select.Option>
                                    ]
                                }
                            </Select>
                        );
                    }}
                />
                <ProDescriptions.Item
                    label="报检区域"
                    labelStyle={labelStyle}
                    dataIndex={['tag']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return (
                            <Select>
                                <Select.Option value="市辖区">衢州市</Select.Option>
                                <Select.Option value="江山市">江山市</Select.Option>
                                <Select.Option value="龙游县">龙游县</Select.Option>
                                <Select.Option value="开化县">开化县</Select.Option>
                                <Select.Option value="常山县">常山县</Select.Option>
                            </Select>
                        );
                    }}
                />

               <ProDescriptions.Item span={2} labelStyle={{display:'none'}} editable={false}>
                    <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8,}}>
                        单位信息
                    </div>
               </ProDescriptions.Item>

                <ProDescriptions.Item
                    label="报检单位"
                    labelStyle={labelStyle}
                    dataIndex={['customerName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />

                {/* <ProDescriptions.Item 
                    label="增值税号"
                    labelStyle={labelStyle}
                    dataIndex={['customerCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                >
                </ProDescriptions.Item> */}

                <ProDescriptions.Item
                    label="接收人"
                    labelStyle={labelStyle}
                    dataIndex={['deliveryInfo', 'receiverName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="接收人电话"
                    labelStyle={labelStyle}
                    dataIndex={['deliveryInfo', 'receiverMobile']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="联系地址"
                    labelStyle={labelStyle}
                    dataIndex={['deliveryInfo', 'deliveryAddressDetail']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />


                <ProDescriptions.Item span={2} labelStyle={{display:'none'}} editable={false}>
                    <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8,}}>
                        费用及开票信息
                    </div>
               </ProDescriptions.Item>

               <ProDescriptions.Item
                    label="发票代码"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceSn']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />

               <ProDescriptions.Item
                    label="缴款单号"
                    labelStyle={labelStyle}
                    dataIndex={['receiptNo']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                {/* <ProDescriptions.Item
                    label="收费方式"
                    labelStyle={labelStyle}
                    dataIndex={['amountInfo', 'payMethod']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                /> */}
                <ProDescriptions.Item
                    label="总金额"
                    labelStyle={labelStyle}
                    dataIndex={['amountInfo', 'totalAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <InputNumber />;
                    }}
                />
                <ProDescriptions.Item
                    label="优惠金额"
                    labelStyle={labelStyle}
                    dataIndex={['amountInfo', 'discountAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <InputNumber />;
                    }}
                />
                <ProDescriptions.Item
                    label="缴款单开具人"
                    labelStyle={labelStyle}
                    dataIndex={['receiptOwnerId']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {entity?.receiptOwner?.name || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <StaffSelect style={{width:200}}/>;
                    }}
                />
                <ProDescriptions.Item
                    label="开票类型"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceType']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom?dom=='ELECTRONIC_NORMAL_INVOICE'?'普票':'专票' : "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return (
                            <Radio.Group>
                                <Radio value={'ELECTRONIC_NORMAL_INVOICE'}>普票</Radio>
                                <Radio value={'ELECTRONIC_SPECIAL_INVOICE'}>专票</Radio>
                            </Radio.Group>
                        );
                    }}
                />
                {/*
                <ProDescriptions.Item
                    label="开票单位名称"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceTitle']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="纳税人识别号"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="开户行"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceBankName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="银行账号"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceBankAccount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="联系电话"
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceTelephone']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="地址"
                    span={2}
                    labelStyle={labelStyle}
                    dataIndex={['invoiceInfo', 'invoiceAddress']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                /> */}
                <ProDescriptions.Item
                    span={2}
                    label="备注"
                    labelStyle={labelStyle}
                    dataIndex={['remark']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
            </ProDescriptions>

            <div>
                <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8}}>
                        关联设备列表
                    </div>
                <Table
                            dataSource={salesOrder.lineList}
                            pagination={false}
                            summary={(pageData:any)=>{
                                return (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={6}>
                                            <div style={{textAlign:"right", fontWeight:"bolder"}}>
                                                设备数量：{salesOrder?.lineList?.length||0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                优惠调整：<span>{salesOrder?.amountInfo?.discountAmount||0}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                路费：{salesOrder?.amountInfo?.shippingAmount||0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                总价：{salesOrder?.amountInfo?.finalTotalAmount||0}元</div>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );   
                            }}
                            expandable={{
                                        expandedRowRender: (record:any) => {
                                            return [
                                                <Descriptions title="设备额外属性" column={4}>
                                                    {(record.extraPropList||[]).map((prop:any)=>{
                                                        return (
                                                            <Descriptions.Item label={prop['label']}>
                                                                {prop?.value}
                                                            </Descriptions.Item>
                                                        );
                                                    })}
                                                </Descriptions>,

                                                <Descriptions title="设备费用明细" column={4}>
                                                    {(record.amountDetailList||[]).map((prop:any)=>{
                                                        return (
                                                            <Descriptions.Item label={prop?.label}>
                                                                {prop?.amount}
                                                            </Descriptions.Item>
                                                        );
                                                    })}
                                                </Descriptions>
                                            ];
                                        },
                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                    }}
                            columns={[
                                {
                                    key: "rowNo",
                                    title: "序号",
                                    dataIndex: "rowNo",
                                    width: 70,
                                    fixed: 'left',
                                },
                                {
                                     key: "productName",
                                     title: "使用单位",
                                     dataIndex: "productName",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "productCode",
                                     title: "设备代码",
                                     dataIndex: "productCode",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "productName",
                                     title: "设备品种",
                                     dataIndex: "deviceType",
                                     width: 100,
                                     ellipsis: true,
                                     render:()=>{
                                        return salesOrder.orderType;
                                     }
                                },
                                {
                                     key: "totalAmount",
                                     title: "费用",
                                     dataIndex: "totalAmount",
                                     width: 100,
                                     ellipsis: true,
                                },
                            ]}
                        >

                        </Table>
            </div>
            

            <div>
                <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8, marginTop:16}}>
                        关联收费列表
                    </div>
                <Table
                            dataSource={paymentList}
                            columns={[
                                {
                                    key: "rowNo",
                                    title: "序号",
                                    dataIndex: "rowNo",
                                    width: 70,
                                    fixed: 'left',
                                },
                                {
                                     key: "payMethod",
                                     title: "收款方式",
                                     dataIndex: "payment",
                                     width: 100,
                                     ellipsis: true,
                                     render:(val)=>{
                                        return val?.payMethod;
                                     }
                                },
                                {
                                     key: "bankSn",
                                     title: "识别号",
                                     dataIndex: "payment",
                                     width: 100,
                                     ellipsis: true,
                                    render:(val)=>{
                                        return val?.bankSn;
                                    }
                                },
                                {
                                     key: "payer",
                                     title: "付款人",
                                     dataIndex: "payment",
                                     width: 100,
                                     ellipsis: true,
                                     render:(val)=>{
                                         return val?.payer;
                                     }
                                },
                                {
                                     key: "consumeAmount",
                                     title: "金额",
                                     dataIndex: "consumeAmount",
                                     width: 100,
                                     ellipsis: true,
                                },
                            ]}
                        >

                        </Table>
            </div>


            <div>
                <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8, marginTop:16}}>
                        关联报告
                    </div>
                <Table
                            dataSource={reportList}
                            columns={[
                                {
                                    key: "rowNo",
                                    title: "序号",
                                    dataIndex: "rowNo",
                                    width: 70,
                                    fixed: 'left',
                                },
                                {
                                     key: "customerName",
                                     title: "使用单位",
                                     dataIndex: "customerName",
                                     width: 220,
                                     ellipsis: true,
                                },
                                {
                                     key: "reportSn",
                                     title: "报告编号",
                                     dataIndex: "reportSn",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "reportDate",
                                     title: "开具日期",
                                     dataIndex: "reportDate",
                                     width: 100,
                                     ellipsis: true,
                                }
                            ]}
                        >

                        </Table>
            </div>

            {/* <div>
                <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', width:'100%', padding:8, marginTop:16}}>
                        关联发票
                    </div>
                <Table
                            dataSource={reportList}
                            columns={[
                                {
                                    key: "rowNo",
                                    title: "序号",
                                    dataIndex: "rowNo",
                                    width: 70,
                                    fixed: 'left',
                                },
                                {
                                     key: "buyerName",
                                     title: "纳税人名称",
                                     dataIndex: "buyerName",
                                     width: 220,
                                     ellipsis: true,
                                },
                                {
                                     key: "buyerCode",
                                     title: "纳税人识别号",
                                     dataIndex: "buyerCode",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "reportDate",
                                     title: "发票代码",
                                     dataIndex: "sn",
                                     width: 100,
                                     ellipsis: true,
                                }
                            ]}
                        >

                        </Table>
            </div> */}
        </Drawer>
    );
}

export default SalesOrderDetail;