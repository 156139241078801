import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import PaymentApi from "../../api/PaymentApi";
import { Payment, PaymentDetailProps } from "../../type/PaymentType";

const PaymentDetail:React.FC<PaymentDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
    };

    const [title, setTitle] = useState<string>(props?.payment?.name);
    const [edited, setEdited] = useState<boolean>(false);
    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Drawer
            title={title + "-详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {...props}
        >
            <ProDescriptions<Payment>
                column={2}
                size={'middle'}
                request={async()=>{
                    const result:Payment = await PaymentApi.findPaymentById(props.payment?.id);
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    }
                }}
                editable={{
                    onSave: async (keyPath: any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const payment = await PaymentApi.patchEditPayment(requestData);
                        setTitle(payment?.name);
                        setEdited(true);
                    }
                }}
            >
                <ProDescriptions.Item
                    label="业务部门ID"
                    labelStyle={labelStyle}
                    dataIndex={['orgId']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="付款人"
                    labelStyle={labelStyle}
                    dataIndex={['payer']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="付款日期"
                    labelStyle={labelStyle}
                    dataIndex={['payDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="银行流水号"
                    labelStyle={labelStyle}
                    dataIndex={['bankSn']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="金币类型"
                    labelStyle={labelStyle}
                    dataIndex={['currencyCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="付款金额"
                    labelStyle={labelStyle}
                    dataIndex={['payAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="核销金额"
                    labelStyle={labelStyle}
                    dataIndex={['checkedAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="待核销金额"
                    labelStyle={labelStyle}
                    dataIndex={['uncheckAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="未核销原因"
                    labelStyle={labelStyle}
                    dataIndex={['uncheckReason']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="备注"
                    labelStyle={labelStyle}
                    dataIndex={['remark']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
            </ProDescriptions>
        </Drawer>
    );
}

export default PaymentDetail;