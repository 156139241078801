

import React from 'react';
import {ColumnHeightOutlined} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface TableSizeSettingProps {
    onChange: (value:SizeType)=>void,
}

const TableSizeSetting:React.FC<TableSizeSettingProps> = (props)=>{

    return (
        <Dropdown 
            overlay={
                <Menu
                    style={{width:100}}
                    onClick={({key})=>{
                        props.onChange(key as SizeType);
                    }}
                    items={[{
                        label: '默认',
                        key: 'default',
                    }, {
                        label: '中等',
                        key: 'middle',
                    }, {
                        label: '紧凑',
                        key: 'small',
                    }]}
                >
                </Menu>
            }
            trigger={['click']}
        >
            <ColumnHeightOutlined style={{fontSize:16}} />
        </Dropdown>
    );
};

export default TableSizeSetting;