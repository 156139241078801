import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, message, Row, Space } from "antd";
import React, { useRef } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import RoleApi from "../../api/RoleApi";
import { RoleAddFormProps, Role } from "../../type/RoleType";

const RoleAddForm:React.FC<RoleAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);

    return (
        <Drawer
            title="新建角色"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:Role = formRef?.current?.getFieldsValue();
                                    const role = await RoleApi.addRole(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建角色成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(role);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label={'角色编码'}
                            name={'code'}
                            rules={[{required:true, message:'角色编码不能为空'}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={'角色名称'}
                            name={'name'}
                            rules={[{required:true, message:'角色名称不能为空'}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={'角色简称'}
                            name={'nameAbbr'}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={'角色别名'}
                            name={'nameAlias'}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={'显示顺序'}
                            name={'sortNo'}
                            rules={[{required:true, message:'显示顺序不能为空'}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <InputNumber style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default RoleAddForm;