import React, {FC, useState, useRef, useReducer, useEffect} from "react";
import {stringify} from 'qs';

import {Table, Space, Modal, Dropdown, Menu, Button, FormInstance, Form, Input, Spin, Divider, message, Radio, Descriptions, Switch, DatePicker, Flex} from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import {UpOutlined, DownOutlined, RedoOutlined} from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type'
import { Product, ProductDetailProps, ProductQto } from './../../type/ProductType';
import ProductApi from "../../api/ProductApi";
import { ColumnsType } from "antd/es/table";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ProFormText } from "@ant-design/pro-form";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import TableUtils from "./../../../../utils/ui/table/TableUtils"
import AddProductForm from "./ProductAddForm";
import ProductDetail from "./ProductDetail";
import ProductModelPropApi from "../../api/ProductModelPropApi";
import ProductModelPriceItemApi from "../../api/ProductModelPriceItemApi";
import OrganizationSelect from "@/modules/system/pages/Organization/OrganizationSelector";
import SalesOrderApi from "../../api/SalesOrderApi";

interface ProductState {
    addFormVisible: boolean,
    detailVisible: boolean,
    queryFormExpanded: boolean,
    currentProduct: Product,
    queryParams: ProductQto,
    pageData: Pagination,
    spinning: boolean,
    selectedRows: Product[],
    validatedFlag: boolean,
    selectedProductList: Product[],
}

interface ProductAction {
    type: string,
    payload: any,
}

const initProductState:ProductState = {
    addFormVisible: false,
    detailVisible: false,
    queryFormExpanded: true,
    currentProduct: {},
    queryParams: {
        status: ''
    },
    pageData: {},
    spinning: false,
    selectedRows: [],
    validatedFlag: true,
    selectedProductList: []
}

const productReducer = (state:ProductState, action:ProductAction):ProductState=>{

    switch(action.type) {
        case 'updateState':
            return {
                ...state,
                ...action.payload||{}
            };
        default:
            return state;
    }
}

const ProductPage:React.FC = ()=>{

    const [productState, dispatch] = useReducer(productReducer, initProductState);

    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);
    const [tableSize, setTableSize] = useState<SizeType>('middle'); 
    const [tableHeight, setTableHeight] = useState<number>(0); 
    const [columns, setColumns] = useState<ColumnsType<Product>>([]);
    const [spinning, setSpinning] = useState<boolean>(false);
    const {addFormVisible, detailVisible, queryFormExpanded, currentProduct,
        queryParams, pageData, selectedRows, validatedFlag, selectedProductList} = productState;
    const [modelId, setModelId] = useState<string>('1003');
    const [propList, setPropList] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [salesOrder, setSalesOrder] = useState({});
    const [businessCompany, setBusinessCompany] = useState('');
    const [businessCode, setBusinessCode] = useState('');

    const buildColumns = ()=>{
        const defaultColumns:ColumnsType<Product> = [
            {
                key: "rowNo",
                title: "序号",
                dataIndex: "rowNo",
                width: 70,
                fixed: 'left',
            },
            {
                 key: "useCompany",
                 title: "使用单位",
                 dataIndex: "useCompany",
                 width: 280,
                 ellipsis: false,
                 render:(val, record)=>{
                    return (
                        <span style={{cursor:'pointer'}} onClick={()=>{
                            setBusinessCompany(val);
                            setBusinessCode(record?.useCompanyCode);
                        }}>{val}</span>
                    );
                 }
            },
            {
                 key: "code",
                 title: "设备代码",
                 dataIndex: "code",
                 width: 220,
                 ellipsis: false,
                 render: (val, record)=>{
                    return <span style={{color: record.inspectedFlag?'green':null}}>{val}</span>
                 }
            },
            {
                 key: "useDeptName",
                 title: "小区",
                 dataIndex: "useDeptName",
                 width: 160,
                 ellipsis: false,
            },
            {
                 key: "productType",
                 title: "设备型号",
                 dataIndex: "propList",
                 width: 100,
                 ellipsis: false,
                 render:(val)=>{
                    for(let i=0; i<val.length; i++){
                        if(['1001001', '1002001', '1003001','1004001','1005001'].indexOf(val[i].modelPropId)>=0){
                            return val[i].value;
                        }
                    }
                    return "";
                 }

            },
            {
                key: "nextCheckDate",
                title: "下检日期",
                dataIndex: "nextCheckDate",
                width: 130,
                ellipsis: false,

           },
            {
                 key: "position",
                 title: "出厂编号",
                 dataIndex: "propList",
                 width: 100,
                 ellipsis: false,
                 render:(val)=>{
                    for(let i=0; i<val.length; i++){
                        if(['1001002', '1002002', '1003002','1004002','1005002'].indexOf(val[i].modelPropId)>=0){
                            return val[i].value;
                        }
                    }
                    return "";
                 }
            },
            
        ];

        return defaultColumns;
    };

    const queryFormFields = [
        <div key='nextCheckDate' style={{display:'inline-block', height:40, width: 260}}>
            <Form.Item
                label="下次检验周期"
                name={"nextCheckDate"}
            >
                <DatePicker.MonthPicker
                    name="nextCheckDate"
                />
            </Form.Item>
        </div>,
        <div key='useByCompanyLike' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="使用单位"
                name={"useByCompanyLike"}
            >
                <Input
                    name="useByCompanyLike"
                />
            </Form.Item>
        </div>,
        <div key='useDeptNameLike' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="小区"
                name={"useDeptNameLike"}
            >
                <Input
                    name="useDeptNameLike"
                />
            </Form.Item>
        </div>,
        <div key='code' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="设备代码"
                name={"code"}
            >
                <Input
                    name="code"
                />
            </Form.Item>
        </div>,
        <div key='factoryNum' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="出厂编号"
                name={"factoryNum"}
            >
                <Input
                    name="factoryNum"
                />
            </Form.Item>
        </div>,
        <div key='orgId' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="组织机构"
                name={"orgId"}
            >
                <OrganizationSelect
                    name="orgId"
                />
            </Form.Item>
        </div>,
        modelId=='1003' && <div key='periodYearType' style={{display:'inline-block', height:40, width: 320}}>
            <Form.Item
                label="检验年类型"
                name={"periodYearType"}
            >
                <Radio.Group name="periodYearType">
                    <Radio value={'检验年'}>检验年</Radio>
                    <Radio value={'检测年'}>检测年</Radio>
                    <Radio value={''}>所有</Radio>
                </Radio.Group>
            </Form.Item>
        </div>,
        <div key='status' style={{display:'inline-block', height:40, width: 350}}>
            <Form.Item
                label="设备状态"
                name={"status"}
            >
                <Radio.Group name="status">
                    <Radio value={'在用'}>在用</Radio>
                    <Radio value={'待用'}>待用</Radio>
                    <Radio value={'停用'}>停用</Radio>
                    <Radio value={''}>所有</Radio>
                </Radio.Group>
            </Form.Item>
        </div>
    ];

    const reloadData = (params:any|undefined)=>{

        setSpinning(true);

        if(queryParams?.nextCheckDate){
            queryParams.nextCheckDateBegin = queryParams.nextCheckDate.startOf('month').format('YYYY-MM-DD') + " 00:00:00";
            queryParams.nextCheckDateEnd = queryParams.nextCheckDate.endOf('month').format('YYYY-MM-DD') + " 23:59:59";
        }else {
            queryParams.nextCheckDateBegin = undefined;
            queryParams.nextCheckDateEnd = undefined;
        }

        if(params?.nextCheckDate) {
            params.nextCheckDateBegin = queryParams.nextCheckDate.startOf('month').format('YYYY-MM-DD') + " 00:00:00";
            params.nextCheckDateEnd = queryParams.nextCheckDate.endOf('month').format('YYYY-MM-DD') + " 23:59:59";
        }

        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
            modelId: params?.modelId||modelId,
            validatedFlag: validatedFlag,
        };

        ProductApi.findProductPage({
            ...newQueryParams,
            nextCheckDate: undefined,
        }).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });

            dispatch({
                type: 'updateState',
                payload: {
                    queryParams: newQueryParams,
                    pageData: pageInfo,
                }
            });
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const deleteProduct = (product:Product)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定删除[" + product.name + "]吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await ProductApi.deleteProduct(product);

                reloadData({});
            }
        });
    }

    const batchDeleteProduct = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "操作确认",
            content: "确定删除所选吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await ProductApi.deleteProduct(row?.id);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: Product
    }> = ({item}) => {

        const items = [];

        items.push({
            key: 'delete',
            label: '删除',
            disabled: item.code==='ADMIN'
        });

        return (
            <Dropdown
                key="row-extra-btns"
                menu={{
                    onClick: ({key})=>{
                        if(key==='delete') {
                            deleteProduct(item);
                        }
                    },
                    items: items,
                }}
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    useEffect(()=>{
        ProductModelPropApi.findProductModelPropList({
            modelId: modelId,
            limit: 1000,
        }).then(list=>{
            setPropList(list);
        });
        ProductModelPriceItemApi.findProductModelPriceItemList({
            modelId: modelId,
            limit: 1000,
        }).then(list=>{
            setPriceList(list);
        });

        setColumns(buildColumns());

        reloadData({});
        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    return (
        <Flex gap={'middle'} vertical={false} style={{width:'100%', height:'100%'}}>
            <Flex flex={1}>
                <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
                    <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                        <Form
                            ref={queryFormRef}
                            onValuesChange={(values)=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        queryParams: {
                                            ...queryParams,
                                            ...values,
                                        }
                                    }
                                });
                            }}
                        >
                            <Space align="start" wrap>
                                { TableUtils.getQueryFields(queryFormFields, queryFormExpanded) }

                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        reloadData({});
                                    }}
                                >
                                    查询
                                </Button>

                                <Button
                                    type="primary"
                                    danger
                                    onClick={()=>{
                                        queryFormRef.current?.resetFields();
                                    }}
                                >
                                    重置
                                </Button>

                                {
                                    queryFormFields.length>2 &&
                                    <Button
                                        type="link"
                                        onClick={()=>{
                                            dispatch({
                                                type: 'updateState',
                                                payload: {
                                                    queryFormExpanded: !queryFormExpanded,
                                                }
                                            });
                                        }}
                                    >
                                        {queryFormExpanded?'收起':'展开'}{queryFormExpanded?<UpOutlined />:<DownOutlined />}
                                    </Button>
                                }
                            </Space>
                        </Form>
                    </div>

                    <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                        <div style={{fontWeight:'bold', display:'inline-block'}}>
                            <Radio.Group onChange={(e) => {
                                setModelId(e.target.value);
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        selectedRows: [],
                                        selectedProductList: [],
                                    }
                                });
                                reloadData({
                                    modelId: e.target.value
                                });
                            }}>
                                <Radio.Button value="1003">电梯</Radio.Button>
                                <Radio.Button value="1004">起重机</Radio.Button>
                                <Radio.Button value="1005">叉车</Radio.Button>
                                <Radio.Button value="1001">锅炉</Radio.Button>
                                <Radio.Button value="1002">压力容器</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div style={{display: 'inline-block', float: 'right'}}>
                            <Space size={16}>
                                {
                                    selectedRows.length>0 &&
                                    <Button type="link">
                                        已选择 <strong>{selectedRows.length}</strong> 条
                                    </Button>
                                }
                                <Switch 
                                    checkedChildren="有效" 
                                    unCheckedChildren="无效" 
                                    defaultChecked={validatedFlag}
                                    onChange={(checked)=>{
                                        dispatch({
                                            type: 'updateState',
                                            payload: {
                                                validatedFlag: checked,
                                            }
                                        });
                                    }}
                                />

                                <Button
                                    onClick={()=>{
                                        if(queryParams?.nextCheckDate){
                                            queryParams.nextCheckDateBegin = queryParams.nextCheckDate.startOf('month').format('YYYY-MM-DD') + " 00:00:00";
                                            queryParams.nextCheckDateEnd = queryParams.nextCheckDate.endOf('month').format('YYYY-MM-DD') + " 23:59:59";
                                        }else {
                                            queryParams.nextCheckDateBegin = undefined;
                                            queryParams.nextCheckDateEnd = undefined;
                                        }
                                
                                        const newQueryParams = {
                                            ...queryParams,
                                            deletedFlag: false,
                                            periodYearType: '检验年',
                                            validatedFlag: validatedFlag,
                                            Authorization: localStorage.getItem("Authorization")
                                        };
                                        window.open(`/api/tenant/crm/product/excel?${stringify(newQueryParams)}`, '_blank');
                                    }}
                                >导出</Button>
                            </Space>
                        </div>
                    </div>
                    <div style={{flex:1, position:'relative'}}>
                        <div
                            ref={container}
                            style={{width: '100%', height:'100%', position:'absolute'}}
                        >
                            <Spin spinning={spinning}>
                                <Table
                                    bordered
                                    dataSource={pageData?.list}
                                    pagination={{
                                        current: queryParams?.page||1,
                                        pageSize: queryParams?.pageSize||10,
                                        total: pageData?.total,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        showQuickJumper: true,
                                        showSizeChanger: true,
                                        showTotal: (total)=>{
                                            return (
                                                <div>
                                                    总共{total}条
                                                </div>
                                            );
                                        },
                                        onChange:(page, pageSize)=>{
                                            reloadData({
                                                page,
                                                pageSize,
                                            });
                                        },
                                        onShowSizeChange:(page, pageSize)=>{
                                            reloadData({
                                                page,
                                                pageSize,
                                            });
                                        }
                                    }}
                                    size={tableSize}
                                    rowKey="id"
                                    columns={columns}
                                    scroll={{x: TableUtils.getTableScrollX(columns), y: TableUtils.getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                                    // expandable={{
                                    //     expandedRowRender: (record:any) => {
                                    //         return [
                                    //             <Descriptions title="设备额外属性" column={4}>
                                    //                 {(record.propList||[]).map((prop:any)=>{
                                    //                     return (
                                    //                         <Descriptions.Item label={propList.filter((item:any)=>item.id==prop.modelPropId)[0]['label']}>
                                    //                             {prop?.value}
                                    //                         </Descriptions.Item>
                                    //                     );
                                    //                 })}
                                    //             </Descriptions>,

                                    //             <Descriptions title="设备费用明细" column={4}>
                                    //                 {(record.priceLineList||[]).map((prop:any)=>{
                                    //                     return (
                                    //                         <Descriptions.Item label={prop?.label}>
                                    //                             {prop?.value}
                                    //                         </Descriptions.Item>
                                    //                     );
                                    //                 })}
                                    //             </Descriptions>
                                    //         ];
                                    //     },
                                    //     rowExpandable: (record) => record.name !== 'Not Expandable',
                                    // }}
                                    rowSelection={{
                                        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                        defaultSelectedRowKeys: [],
                                        onChange:(selectedRowKeys, selectedRows)=>{

                                            if(selectedProductList.length<1){
                                                selectedProductList.push(...selectedRows);
                                            } else {
                                                const record = selectedProductList[0];

                                                selectedRows.forEach((item)=>{
                                                    for(let i=0; i<selectedProductList.length; i++){
                                                        if(item.id===selectedProductList[i].id){
                                                            return;
                                                        }
                                                    }
                                                    // if(item.useCompany!=record.useCompany){
                                                            
                                                    //     Modal.error({
                                                    //         title: '系统错误',
                                                    //         content: `报检单位与${record.useCompany}不相符`,
                                                    //     });
                                                    //     return;
                                                    // }

                                                    if(item.deviceType!=record.deviceType){
                                                        
                                                        Modal.error({
                                                            title: '系统错误',
                                                            content: `报检类型不是${record.deviceType}`,
                                                        });
                                                        return;
                                                    }

                                                    selectedProductList.push(item);
                                                })
                                            }

                                            dispatch({
                                                type: 'updateState',
                                                payload: {
                                                    selectedRows: selectedRows,
                                                    selectedProductList: [...selectedProductList],
                                                }
                                            });
                                        }
                                    }}
                                />
                            </Spin>
                        </div>
                    </div>

                    {
                        addFormVisible &&
                        <AddProductForm
                            onClose={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        addFormVisible: false,
                                    }
                                });
                            }}
                            onSuccess={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        addFormVisible: false,
                                    }
                                });
                                reloadData({});
                            }}
                        />
                    }

                    {
                        detailVisible &&
                        <ProductDetail
                            product={currentProduct}
                            onClose={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        detailVisible: false,
                                    }
                                });
                            }}
                            onSuccess={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        detailVisible: false,
                                    }
                                });
                                reloadData({});
                            }}
                        />
                    }
                </div>
            </Flex>
            <div style={{width: 570}}>
                <div  style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                    
                    报检单位：<Input value={businessCompany} onChange={(e)=>{setBusinessCompany(e.target.value); setBusinessCode('')}} style={{width:220}}/>
                    
                    <div style={{display: 'inline-block', float: 'right'}}>
                        <Space>
                            <Button 
                                type="primary"
                                onClick={async ()=>{

                                    if(selectedProductList.length<1){
                                        Modal.error({
                                            title: '系统错误',
                                            content: '请选择要报检的设备'
                                        });
                                        return;
                                    }

                                    const record = selectedProductList[0];
                                    let deliveryInfo:any = {};
                                    if(businessCompany){
                                        deliveryInfo = await SalesOrderApi.findSalesOrderAddress(businessCompany);
                                    }

                                    const salesOrder = {
                                        customerName:businessCompany,
                                        customerCode: businessCode,
                                        deliveryInfo: {
                                            receiverName: deliveryInfo?.linkman,
                                            receiverMobile: deliveryInfo?.mobile,
                                            deliveryAddressDetail: deliveryInfo?.address,
                                        },
                                        orderType: record.deviceType,
                                        createdFrom: '大厅',
                                        businessCompany: businessCompany,
                                        lineList: selectedProductList.map((item, idx)=>{
                                            return {
                                                rowNo: idx+1,
                                                productId: item.id,
                                                productCode: item.code,
                                                productName: item.useCompany,
                                                quantity: 1,
                                                extraPropList: item.propList.map(prop=>{
                                                    let productProp:any = {};
                                                    for(let i=0; i<propList.length; i++){
                                                        if(propList[i].id==prop.modelPropId){
                                                            productProp = propList[i];
                                                        }
                                                    }
                                                    return {
                                                        code: productProp.code,
                                                        label: productProp.label,
                                                        valueType: productProp.valueType,
                                                        value: prop.value,
                                                        sortNo: prop.sortNo,
                                                    }
                                                })
                                            };
                                        })
                                    };
                                    setSalesOrder(salesOrder);

                                    dispatch({
                                        type: 'updateState',
                                        payload: {
                                            addFormVisible: true,
                                        }
                                    });
                                }}
                            >
                                报检
                            </Button>
                        </Space>
                    </div>
                </div>

                <Table
                    scroll={{x: 570}}
                    dataSource={selectedProductList}
                    columns={[{
                        key: "rowNo",
                        title: "序号",
                        dataIndex: "rowNo",
                        width: 70,
                    },
                    {
                        key: "op",
                        title: "操作",
                        dataIndex: "op",
                        width: 100,
                        render: (value, record)=>{
                            return <span onClick={()=>{
                                let array  = selectedProductList.filter((item)=>{
                                    return item.id!=record.id;
                                });
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        selectedProductList: array,
                                    }
                                })
                            }}>删除</span>
                        }
                    },
                    {
                        key: "code",
                        title: "设备代码",
                        dataIndex: "code",
                        width: 100,
                        ellipsis: false,
                    },
                    {
                        key: "installAddress",
                        title: "安装地址",
                        dataIndex: "installAddress",
                        width: 100,
                        ellipsis: false,
                    },
                    {
                        key: "useCompany",
                        title: "使用单位",
                        dataIndex: "useCompany",
                        width: 100,
                        ellipsis: false,
                    },
                    {
                        key: "nextCheckDate",
                        title: "下次检验日期",
                        dataIndex: "nextCheckDate",
                        width: 100,
                        ellipsis: false,
                    },]}
                >

                </Table>
            </div>
            {
                addFormVisible &&
                <AddProductForm
                    salesOrder={salesOrder}
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                                selectedRows: [],
                                selectedProductList: [],
                            }
                        });
                    }}
                />
            }
        </Flex>
    );
};

export default ProductPage;