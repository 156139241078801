import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber, Table } from "antd";
import React, { useEffect, useState } from "react";
import _ from 'lodash';
import InvoiceApi from "../../api/InvoiceApi";
import { Invoice, InvoiceDetailProps } from "../../type/InvoiceType";
import TableUtils from "../../../../utils/ui/table/TableUtils";
import {ProTableEditableFnType} from '@ant-design/pro-utils'

const InvoiceDetail:React.FC<InvoiceDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
        width: 180,
        textAlign: 'right'
    };

    const [title, setTitle] = useState<string>(props?.invoice?.name);
    const [editable, setEditabled] = useState<false|undefined>(undefined);
    const [edited, setEdited] = useState<boolean>(false);
    const [invoiceLines, setInvoiceLines] = useState<any[]>([]);

    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    const invoiceLinesColumns = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 50,
        },
        {
            key: "productName",
            title: "产品名称",
            dataIndex: "productName",
            width: 180,
            editable: editable,
        },
        {
            key: "specs",
            title: "产品规格",
            dataIndex: "specs",
            width: 180,
            editable: editable,
        },
        {
            key: "unity",
            title: "单位",
            dataIndex: "unity",
            width: 80,
            editable: editable,
        },
        {
            key: "quantity",
            title: "数量",
            dataIndex: "quantity",
            width: 100,
            editable: editable,
        },
        {
            key: "taxedUnitPrice",
            title: "含税单价",
            dataIndex: "taxedUnitPrice",
            width: 120,
            editable: editable,
        },
        {
            key: "taxRate",
            title: "税率",
            dataIndex: "taxRate",
            width: 80,
            editable: editable,
        },
        {
            key: "totalTaxedAmount",
            title: "含税总价",
            dataIndex: "totalTaxedAmount",
            width: 120,
            render: (val:any, rec:any)=>{
                return (rec.quantity||0)*(rec.taxedUnitPrice||0);
            }
        },
    ];

    return (
        <Drawer
            title={title + "-详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {...props}
        >
            <ProDescriptions<Invoice>
                column={2}
                size={'middle'}
                request={async()=>{
                    const result:Invoice = await InvoiceApi.findInvoiceById(props.invoice?.id);
                    setInvoiceLines(result?.lines);
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    }
                }}
                editable={{
                    onSave: async (keyPath: any, newData:any, oldData:any)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const invoice = await InvoiceApi.patchEditInvoice(requestData);
                        setTitle(invoice?.name);
                        setEdited(true);
                    }
                }}
            >
                <ProDescriptions.Item
                    label="发票代码"
                    dataIndex={['code']}
                    valueType="text"
                    copyable={false}
                    editable={editable}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="开票号码"
                    dataIndex={['sn']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="开票日期"
                    dataIndex={['invoiceDate']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="客户名称"
                    dataIndex={['buyerName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="客户统一信用代码"
                    dataIndex={['buyerCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="客户开户行"
                    dataIndex={['buyerBankName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="购买方银行账号"
                    dataIndex={['buyerBankAccount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="客户地址"
                    dataIndex={['buyerAddress']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="客户电话"
                    dataIndex={['buyerTelephone']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="销售方名称"
                    dataIndex={['sellerName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="销售方统一信用代码"
                    dataIndex={['sellerCode']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="销售方开户行名称"
                    dataIndex={['sellerBankName']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="销售方银行账号"
                    dataIndex={['sellerBankAccount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="销售方地址"
                    dataIndex={['sellerAddress']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="销售方电话"
                    dataIndex={['sellerTelephone']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item>

                </ProDescriptions.Item>

                <ProDescriptions.Item span={2}>
                    <Table 
                        style={{width:'100%'}}
                        columns={invoiceLinesColumns}
                    />
                </ProDescriptions.Item>
                
                <ProDescriptions.Item
                    label="总金额"
                    dataIndex={['totalAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="总税额"
                    dataIndex={['totalTaxAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="含税总金额"
                    dataIndex={['totalTaxedAmount']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="备注"
                    dataIndex={['remark']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="收款人"
                    dataIndex={['payee']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="审核人"
                    dataIndex={['reviewer']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="开票人"
                    dataIndex={['drawer']}
                    valueType="text"
                    copyable={false}
                    render={(dom:any, entity:any, index:any, action:any) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
            </ProDescriptions>
        </Drawer>
    );
}

export default InvoiceDetail;