
import {ProductCategory, ProductCategoryQto} from './../type/ProductCategoryType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProductCategory = async (params:ProductCategory)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product-category`, params);
};


const editProductCategory = async (params:ProductCategory)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product-category/${params.id}`, params);
};


const patchEditProductCategory = async (params:ProductCategory)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product-category/${params.id}`, params);
};


const deleteProductCategory = async (params:ProductCategory)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product-category/${params.id}`, params);
};


const findProductCategoryById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-category/${params}`, params);
};

const findProductCategoryList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-category/list`, params);
};

const findProductCategoryPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-category/page`, params);
};

const findProductCategoryTree = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-category/tree`, params);
};


export default {
    addProductCategory,
    editProductCategory,
    patchEditProductCategory,
    deleteProductCategory,
    findProductCategoryById,
    findProductCategoryList,
    findProductCategoryPage,
    findProductCategoryTree,
};


