
import {Role, RoleQto} from './../type/RoleType';
import HttpInvoker from '../../../utils/http/HttpInvoker';


const addRole = async (params:Role)=>{

    return HttpInvoker.postObject(`/api/tenant/system/role`, params);
};


const editRole = async (params:Role)=>{

    return HttpInvoker.putObject(`/api/tenant/system/role/${params.id}`, params);
};


const patchEditRole = async (params:Role)=>{

    return HttpInvoker.patchObject(`/api/tenant/system/role/${params.id}`, params);
};


const deleteRole = async (params:Role)=>{

    return HttpInvoker.deleteObject(`/api/tenant/system/role/${params.id}`, params);
};


const findRoleById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/system/role/${params}`, params);
};

const findRoleList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/role/list`, params);
};

const findRolePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/role/page`, params);
};


export default {
    addRole,
    editRole,
    patchEditRole,
    deleteRole,
    findRoleById,
    findRoleList,
    findRolePage,
};


