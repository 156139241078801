import React, {FC, useState, useRef, useReducer, useEffect} from "react";

import {Table, Space, Modal, Dropdown, Menu, Button, FormInstance, Form, Input, Spin, Divider, message} from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import {UpOutlined, DownOutlined, RedoOutlined} from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type'
import { Customer, CustomerDetailProps, CustomerQto } from './../../type/CustomerType';
import CustomerApi from "../../api/CustomerApi";
import { ColumnsType } from "antd/es/table";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ProFormText } from "@ant-design/pro-form";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import TableUtils from "./../../../../utils/ui/table/TableUtils"
import AddCustomerForm from "./CustomerAddForm";
import CustomerDetail from "./CustomerDetail";

interface CustomerState {
    addFormVisible: boolean,
    detailVisible: boolean,
    queryFormExpanded: boolean,
    currentCustomer: Customer,
    queryParams: CustomerQto,
    pageData: Pagination,
    spinning: boolean,
    selectedRows: Customer[]
}

interface CustomerAction {
    type: string,
    payload: any,
}

const initCustomerState:CustomerState = {
    addFormVisible: false,
    detailVisible: false,
    queryFormExpanded: true,
    currentCustomer: {},
    queryParams: {},
    pageData: {},
    spinning: false,
    selectedRows: [],
}

const customerReducer = (state:CustomerState, action:CustomerAction):CustomerState=>{

    switch(action.type) {
        case 'updateState':
            return {
                ...state,
                ...action.payload||{}
            };
        default:
            return state;
    }
}

const CustomerPage:React.FC = ()=>{

    const [customerState, dispatch] = useReducer(customerReducer, initCustomerState);

    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);
    const [tableSize, setTableSize] = useState<SizeType>('middle'); 
    const [tableHeight, setTableHeight] = useState<number>(0); 
    const [columns, setColumns] = useState<ColumnsType<Customer>>([]);
    const [spinning, setSpinning] = useState<boolean>(false);
    const {addFormVisible, detailVisible, queryFormExpanded, currentCustomer,
        queryParams, pageData, selectedRows} = customerState;

    const defaultColumns:ColumnsType<Customer> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
             key: "name",
             title: "客户名称",
             dataIndex: "name",
             width: 100,
             ellipsis: true,
        },
        {
             key: "code",
             title: "客户代码",
             dataIndex: "code",
             width: 100,
             ellipsis: true,
        },
        {
             key: "type",
             title: "客户类型",
             dataIndex: "type",
             width: 100,
             ellipsis: true,
        },
        {
             key: "remark",
             title: "备注",
             dataIndex: "remark",
             width: 100,
             ellipsis: true,
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    <a key='view' onClick={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                currentCustomer: record,
                                detailVisible: true,
                            }
                        });
                        }}>
                        查看
                    </a>,
                    <Divider type="vertical" key={'split1'}/>,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const queryFormFields = [
        <div key='name' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="名称"
                name={"name"}
            >
                <Input
                    name="name"
                />
            </Form.Item>
        </div>,
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
        };

        CustomerApi.findCustomerPage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });

            dispatch({
                type: 'updateState',
                payload: {
                    queryParams: newQueryParams,
                    pageData: pageInfo,
                }
            });
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const deleteCustomer = (customer:Customer)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定删除[" + customer.name + "]吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await CustomerApi.deleteCustomer(customer);

                reloadData({});
            }
        });
    }

    const batchDeleteCustomer = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "操作确认",
            content: "确定删除所选吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await CustomerApi.deleteCustomer(row?.id);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: Customer
    }> = ({item}) => {

        const items = [];

        items.push({
            key: 'delete',
            label: '删除',
            disabled: item.code==='ADMIN'
        });

        return (
            <Dropdown
                key="row-extra-btns"
                menu={{
                    onClick: ({key})=>{
                        if(key==='delete') {
                            deleteCustomer(item);
                        }
                    },
                    items: items,
                }}
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    useEffect(()=>{
        setColumns(defaultColumns);
        reloadData({});
        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <Form
                    ref={queryFormRef}
                    onValuesChange={(values)=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                queryParams: {
                                    ...queryParams,
                                    ...values,
                                }
                            }
                        });
                    }}
                >
                    <Space align="start" wrap>
                        { TableUtils.getQueryFields(queryFormFields, queryFormExpanded) }

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{

                                }}
                            >
                                {queryFormExpanded?'收起':'展开'}{queryFormExpanded?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </Form>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    列表
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        {
                            selectedRows.length>0 &&
                            <Button type="link">
                                已选择 <strong>{selectedRows.length}</strong> 条
                            </Button>
                        }

                        <Button
                            type="primary"
                            onClick={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        addFormVisible: true,
                                    }
                                });
                            }}
                        >
                            新建
                        </Button>
                        <Dropdown.Button
                            menu={{
                                onClick:({key})=>{
                                    if(key==='batchDelete'){
                                        batchDeleteCustomer();
                                    }
                                },
                                items: [{
                                    key: 'batchDelete',
                                    label: '批量删除',
                                }]
                            }}
                        >
                            批量操作
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            bordered
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                showTotal: (total)=>{
                                    return (
                                        <div>
                                            总共{total}条
                                        </div>
                                    );
                                },
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page,
                                        pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page,
                                        pageSize,
                                    });
                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: TableUtils.getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                            rowSelection={{
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                defaultSelectedRowKeys: [],
                                onChange:(selectedRowKeys, selectedRows)=>{
                                    dispatch({
                                        type: 'updateState',
                                        payload: {
                                            selectedRows,
                                        }
                                    });
                                }
                            }}
                        />
                    </Spin>
                </div>
            </div>

            {
                addFormVisible &&
                <AddCustomerForm
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                        reloadData({});
                    }}
                />
            }

            {
                detailVisible &&
                <CustomerDetail
                    customer={currentCustomer}
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                detailVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                detailVisible: false,
                            }
                        });
                        reloadData({});
                    }}
                />
            }
        </div>
    );
};

export default CustomerPage;