import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, message, Row, Space } from "antd";
import React, { useRef } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import ProductApi from "../../api/ProductApi";
import { ProductAddFormProps, Product } from "../../type/ProductType";

const ProductAddForm:React.FC<ProductAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:Product = formRef?.current?.getFieldsValue();
                                    const Product = await ProductApi.addProduct(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(Product);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="设备类别"
                            name={['deviceType']}
                            rules={[{required:false, message: "设备类别不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="模型ID"
                            name={['modelId']}
                            rules={[{required:false, message: "模型ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="类别ID"
                            name={['categoryId']}
                            rules={[{required:false, message: "类别ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="安装区域代码"
                            name={['installAreaCode']}
                            rules={[{required:false, message: "安装区域代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="安装地址"
                            name={['installAddress']}
                            rules={[{required:false, message: "安装地址不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="使用单位"
                            name={['useCompany']}
                            rules={[{required:false, message: "使用单位不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="安装单位"
                            name={['installCompany']}
                            rules={[{required:false, message: "安装单位不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="检验类型"
                            name={['checkType']}
                            rules={[{required:false, message: "检验类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="下次检验日期"
                            name={['nextCheckDate']}
                            rules={[{required:false, message: "下次检验日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="最近检验日期"
                            name={['lastCheckDate']}
                            rules={[{required:false, message: "最近检验日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="最近重大修理日期"
                            name={['lastRepairDate']}
                            rules={[{required:false, message: "最近重大修理日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="最近改造日期"
                            name={['lastReformDate']}
                            rules={[{required:false, message: "最近改造日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="设备启用日期"
                            name={['installDate']}
                            rules={[{required:false, message: "设备启用日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="设备启用日期"
                            name={['useBeginDate']}
                            rules={[{required:false, message: "设备启用日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="任务状态"
                            name={['taskStatus']}
                            rules={[{required:false, message: "任务状态不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="任务单编号"
                            name={['taskCode']}
                            rules={[{required:false, message: "任务单编号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="设备代码"
                            name={['code']}
                            rules={[{required:false, message: "设备代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="产品名称"
                            name={['name']}
                            rules={[{required:false, message: "产品名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default ProductAddForm;