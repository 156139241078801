import React, {FC, useState, useRef, useReducer, useEffect} from "react";

import {Table, Space, Modal, Dropdown, Menu, Button, FormInstance, Form, Input, Spin, Divider, message, Layout, Tree} from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import {UpOutlined, DownOutlined, RedoOutlined} from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type'
import { Post, PostDetailProps, PostQto } from './../../type/PostType';
import PostApi from "../../api/PostApi";
import { ColumnsType } from "antd/es/table";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ProFormText } from "@ant-design/pro-form";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import TableUtils from "./../../../../utils/ui/table/TableUtils"
import AddPostForm from "./PostAddForm";
import PostDetail from "./PostDetail";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";

interface PostState {
    addFormVisible: boolean,
    detailVisible: boolean,
    queryFormExpanded: boolean,
    currentPost: Post,
    queryParams: PostQto,
    pageData: Pagination,
    spinning: boolean,
    selectedRows: Post[],
    treeData: [],
}

interface PostAction {
    type: string,
    payload: any,
}

const initPostState:PostState = {
    addFormVisible: false,
    detailVisible: false,
    queryFormExpanded: false,
    currentPost: {},
    queryParams: {},
    pageData: {},
    spinning: false,
    selectedRows: [],
    treeData: [],
}

const postReducer = (state:PostState, action:PostAction):PostState=>{

    switch(action.type) {
        case 'updateState':
            return {
                ...state,
                ...action.payload||{}
            };
        default:
            return state;
    }
}

const PostPage:React.FC = ()=>{

    const [postState, dispatch] = useReducer(postReducer, initPostState);

    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);
    const [tableSize, setTableSize] = useState<SizeType>('middle'); 
    const [tableHeight, setTableHeight] = useState<number>(0); 
    const [columns, setColumns] = useState<ColumnsType<Post>>([]);
    const [spinning, setSpinning] = useState<boolean>(false);
    const [treeLoading, setTreeLoading] = useState<boolean>(false);
    const {addFormVisible, detailVisible, queryFormExpanded, currentPost,
        queryParams, pageData, selectedRows, treeData} = postState;

    const defaultColumns:ColumnsType<Post> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "parentId",
            title: "上级职务ID",
            dataIndex: "parentId",
            width: 100,
            ellipsis: true,
        },
        {
            key: "code",
            title: "职务编码",
            dataIndex: "code",
            width: 100,
            ellipsis: true,
        },
        {
            key: "name",
            title: "职务名称",
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            key: "nameAbbr",
            title: "职务简称",
            dataIndex: "nameAbbr",
            width: 100,
            ellipsis: true,
        },
        {
            key: "sortNo",
            title: "显示顺序",
            dataIndex: "sortNo",
            width: 100,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    <a key='view' onClick={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                currentPost: record,
                                detailVisible: true,
                            }
                        });
                        }}>
                        查看
                    </a>,
                    <Divider type="vertical" key={'split1'}/>,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const queryFormFields = [
        <div key='name' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="职务名称"
                name={"name"}
            >
                <Input
                    name="name"
                />
            </Form.Item>
        </div>,
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
        };

        PostApi.findPostTree({deletedFlag:false}).then(treeData=>{
            PostApi.findPostPage(newQueryParams).then(pageInfo=>{
                pageInfo.list.forEach((i:any,idx:number)=>{
                    i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
                });
    
                dispatch({
                    type: 'updateState',
                    payload: {
                        queryParams: newQueryParams,
                        pageData: pageInfo,
                        treeData: treeData,
                    }
                });
            });
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const setRootId = (rootId:string)=>{
        reloadData({
            rootId,
        });
    }

    const deletePost = (post:Post)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定删除职务[" + post.name + "]吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await PostApi.deletePost(post);

                reloadData({});
            }
        });
    }

    const batchDeletePost = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "操作确认",
            content: "确定删除所选职务吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await PostApi.deletePost(row?.id);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: Post
    }> = ({item}) => {

        const items = [];

        items.push({
            key: 'delete',
            label: '删除',
            disabled: item.code==='ADMIN'
        });

        return (
            <Dropdown
                key="row-extra-btns"
                menu={{
                    onClick: ({key})=>{
                        if(key==='delete') {
                            deletePost(item);
                        }
                    },
                    items: items, 
                }}
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    useEffect(()=>{
        setColumns(defaultColumns);
        reloadData({});
        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <Form
                    ref={queryFormRef}
                    onValuesChange={(values)=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                queryParams: {
                                    ...queryParams,
                                    ...values,
                                }
                            }
                        });
                    }}
                >
                    <Space align="start" wrap>
                        { TableUtils.getQueryFields(queryFormFields, queryFormExpanded) }

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{

                                }}
                            >
                                {queryFormExpanded?'收起':'展开'}{queryFormExpanded?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </Form>
            </div>

            <div style={{flex:1}}>
                <Layout style={{width:'100%', height:'100%'}}>
                    <Sider style={{backgroundColor:'white'}} width={220}>
                        <div style={{display:'flex', width:'100%', height:'100%', flexDirection: 'column'}}>
                            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                                <div style={{fontWeight:'bold', display:'inline-block'}}>
                                    职务列表
                                </div>
                            </div>

                            <div style={{flex:1}}>
                                <Spin spinning={treeLoading} tip="数据加载中..." >
                                    <Tree
                                        treeData={treeData}
                                        fieldNames={{
                                            title: 'name',
                                            key: 'id',
                                            children: 'children'
                                        }}
                                        onSelect={(selectedKeys)=>{
                                            setRootId(selectedKeys[0]?selectedKeys[0].toString():"");
                                        }}
                                    />
                                </Spin>
                            </div>
                            <div style={{position: 'absolute', height:'100%', width:8, background: '#f5f5f5', right:0}}>

                            </div>
                        </div>
                    </Sider>
                    <Content style={{paddingLeft:8}}>
                        <div style={{display:'flex', width:'100%', height:'100%', flexDirection: 'column'}}>
                            <div>
                                <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                                    <div style={{fontWeight:'bold', display:'inline-block'}}>
                                        职务列表
                                    </div>
                                    <div style={{display: 'inline-block', float: 'right'}}>
                                        <Space size={16}>
                                            {
                                                selectedRows.length>0 &&
                                                <Button type="link">
                                                    已选择 <strong>{selectedRows.length}</strong> 条
                                                </Button>
                                            }

                                            <Button
                                                type="primary"
                                                onClick={()=>{
                                                    dispatch({
                                                        type: 'updateState',
                                                        payload: {
                                                            addFormVisible: true,
                                                        }
                                                    });
                                                }}
                                            >
                                                新建
                                            </Button>
                                            <Dropdown.Button
                                                menu={{
                                                    onClick: ({key})=>{
                                                        if(key==='batchDelete'){
                                                            batchDeletePost();
                                                        }
                                                    },
                                                    items: [{
                                                        key: 'batchDelete',
                                                        label: '批量删除',
                                                    }]
                                                }}
                                            >
                                                批量操作
                                            </Dropdown.Button>
                                            <RedoOutlined
                                                style={{fontSize:16, cursor:'pointer'}}
                                                onClick={()=>{
                                                    reloadData({});
                                                }}
                                            />
                                            <TableSizeSetting
                                                onChange={(val)=>{
                                                    setTableSize(val)
                                                }}
                                            />
                                            <TableColumnSetting
                                                columns={columns}
                                                onChange={(value)=>{
                                                    setColumns(value)
                                                }}
                                            />
                                        </Space>
                                    </div>
                                </div>
                            </div>
                            <div style={{flex:1, position:'relative'}}>
                                <div
                                    ref={container}
                                    style={{width: '100%', height:'100%', position:'absolute'}}
                                >
                                    <Spin spinning={spinning}>
                                        <Table
                                            bordered
                                            dataSource={pageData?.list}
                                            pagination={{
                                                current: queryParams?.page||1,
                                                pageSize: queryParams?.pageSize||10,
                                                total: pageData?.total,
                                                pageSizeOptions: [10, 20, 50, 100],
                                                showQuickJumper: true,
                                                showSizeChanger: true,
                                                onChange:(page, pageSize)=>{
                                                    reloadData({
                                                        page,
                                                        pageSize,
                                                    });
                                                },
                                                onShowSizeChange:(page, pageSize)=>{
                                                    reloadData({
                                                        page,
                                                        pageSize,
                                                    });
                                                }
                                            }}
                                            size={tableSize}
                                            rowKey="id"
                                            columns={columns}
                                            scroll={{x: TableUtils.getTableScrollX(columns), y: TableUtils.getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                                            rowSelection={{
                                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                                defaultSelectedRowKeys: [],
                                                onChange:(selectedRowKeys, selectedRows)=>{
                                                    dispatch({
                                                        type: 'updateState',
                                                        payload: {
                                                            selectedRows,
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>

            {
                addFormVisible &&
                <AddPostForm
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                        reloadData({});
                    }}
                />
            }

            {
                detailVisible &&
                <PostDetail
                    post={currentPost}
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                detailVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                detailVisible: false,
                            }
                        });
                        reloadData({});
                    }}
                />
            }
        </div>
    );
};

export default PostPage;