import React, {FC, useState, useRef, useReducer, useEffect, useContext} from "react";
import {stringify} from 'qs';
import {EventEmitter} from 'events';

import {Table, Space, Modal, Dropdown, Menu, Button, FormInstance, Form, Input, Spin, Divider, message, Radio, DatePicker} from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type { GetRef, InputRef } from 'antd';
import {UpOutlined, DownOutlined, RedoOutlined} from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type'
import { SalesOrder, SalesOrderDetailProps, SalesOrderQto } from './../../type/SalesOrderType';
import SalesOrderApi from "../../api/SalesOrderApi";
import { ColumnsType } from "antd/es/table";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ProFormDatePicker, ProFormText } from "@ant-design/pro-form";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import TableUtils from "./../../../../utils/ui/table/TableUtils"
import AddSalesOrderForm from "./SalesOrderAddForm";
import SalesOrderDetail from "./SalesOrderDetail";
import { render } from "@testing-library/react";
import StaffSelect from "@/modules/system/pages/Staff/StaffSelector";
import OrganizationSelect from "@/modules/system/pages/Organization/OrganizationSelector";

type TableFormInstance<T> = GetRef<typeof Form<T>>;
const eventBus = new EventEmitter();


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
  }


const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  
  interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof SalesOrder;
    record: SalesOrder;
    handleSave: (record: SalesOrder) => void;
  }


  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  } ) => {
    const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex=='invoiceInfo'?['invoiceInfo', 'invoiceSn']:dataIndex}
        rules={[
          {
            required: false,
            message: `${title}不能为空.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
  };

interface SalesOrderState {
    addFormVisible: boolean,
    detailVisible: boolean,
    queryFormExpanded: boolean,
    currentSalesOrder: SalesOrder,
    queryParams: SalesOrderQto,
    pageData: Pagination,
    spinning: boolean,
    selectedRows: SalesOrder[]
}

interface SalesOrderAction {
    type: string,
    payload: any,
}

const initSalesOrderState:SalesOrderState = {
    addFormVisible: false,
    detailVisible: false,
    queryFormExpanded: true,
    currentSalesOrder: {},
    queryParams: {},
    pageData: {},
    spinning: false,
    selectedRows: [],
}

const salesOrderReducer = (state:SalesOrderState, action:SalesOrderAction):SalesOrderState=>{

    switch(action.type) {
        case 'updateState':
            return {
                ...state,
                ...action.payload||{}
            };
        default:
            return state;
    }
}

const SalesOrderPage:React.FC = ()=>{

    const [salesOrderState, dispatch] = useReducer(salesOrderReducer, initSalesOrderState);

    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);
    const [tableSize, setTableSize] = useState<SizeType>('middle'); 
    const [tableHeight, setTableHeight] = useState<number>(0); 
    const [columns, setColumns] = useState([]);
    const [spinning, setSpinning] = useState<boolean>(false);
    const {addFormVisible, detailVisible, queryFormExpanded, currentSalesOrder,
        queryParams, pageData, selectedRows} = salesOrderState;

        const handleSave = async (row: SalesOrder) => {
            
            const newRow = await SalesOrderApi.patchEditSalesOrder({
                id: row.id,
                invoiceInfo: {
                    invoiceSn: row.invoiceInfo?.invoiceSn,
                },
                taskNo: row.taskNo,
            });

            eventBus.emit("updateTableRow", newRow);
          };

    const defaultColumns = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 40,
            fixed: 'left',
        },
        {
             key: "orderType",
             title: "业务类型",
             dataIndex: "orderType",
             width: 100,
             ellipsis: false,
        },
        {
             key: "customerName",
             title: "申请单位",
             dataIndex: "customerName",
             width: 240,
             ellipsis: false,
        },
        {
             key: "receiptNo",
             title: "缴款单号",
             dataIndex: "receiptNo",
             width: 110,
             ellipsis: false,
        },
        // {
        //      key: "checkSn",
        //      title: "待核销序号",
        //      dataIndex: "checkSn",
        //      width: 110,
        //      ellipsis: false,
        //      editable: true,
        //      render: (val)=>{
        //         return val?val:'空';
        //      }
        // },
        {
             key: "taskNo",
             title: "任务单号",
             dataIndex: "taskNo",
             width: 150,
             ellipsis: false,
             editable: true,
             render: (val)=>{
                return val?val:'空';
             }
        },
        {
             key: "invoiceSn",
             title: "发票代码",
             dataIndex: "invoiceInfo",
             width: 140,
             ellipsis: false,
             editable: true,
             render: (val)=>{
                return val&&val.invoiceSn?val?.invoiceSn:'空';
             },
        },
        {
             key: "finalTotalAmount",
             title: "金额",
             dataIndex: "amountInfo",
             width: 100,
             ellipsis: false,
             render: (val)=>{
                return val?.finalTotalAmount;
             }
        },
        {
             key: "payStatus",
             title: "支付状态",
             dataIndex: "amountInfo",
             width: 100,
             ellipsis: false,
             render: (val)=>{
                switch(val?.payStatus) {
                    case 'UNPAID':
                        return '待支付';
                    case 'PAID_PART':
                        return '支付中';
                    case 'PAID':
                        return '已支付';
                    default:
                        return '待支付'
                }
             }
        },
        {
             key: "balancedStatus",
             title: "平账状态",
             dataIndex: "amountInfo",
             width: 100,
             ellipsis: false,
             render:(val, record)=>{
                return <a onClick={()=>{
                    Modal.confirm({
                        title: '操作提醒',
                        content: '你确认修改平账状态吗？',
                        onOk: async ()=>{
                            await SalesOrderApi.patchEditSalesOrder({
                                id: record.id,
                                amountInfo: {
                                    balancedStatus: '已平账'==val?.balancedStatus?'未平账':'已平账'
                                }
                            });
                            reloadData({});
                        }
                    });
                }}>{val?.balancedStatus||"未平账"}</a>;
             }
        },
        {
            key: "uniSettlementFlag",
            title: "统一支付",
            dataIndex: "uniSettlementFlag",
            width: 100,
            ellipsis: false,
            render:(val, record)=>{
               return <a onClick={()=>{
                   Modal.confirm({
                       title: '操作提醒',
                       content: '你确认统一支付状态吗？',
                       onOk: async ()=>{
                           await SalesOrderApi.patchEditSalesOrder({
                               id: record.id,
                               uniSettlementFlag: !val,
                           });
                           reloadData({});
                       }
                   });
               }}>{val?"是":"否"}</a>;
            }
       },
        {
             key: "createdFrom",
             title: "业务来源",
             dataIndex: "createdFrom",
             width: 100,
             ellipsis: false,

        },
        {
             key: "remark",
             title: "备注",
             dataIndex: "remark",
             width: 120,
             ellipsis: false,
        },
        {
             key: "taskDate",
             title: "任务单日期",
             dataIndex: "taskDate",
             width: 100,
             ellipsis: false,
        },
        {
             key: "promptDate",
             title: "催缴日期",
             dataIndex: "promptDate",
             width: 100,
             ellipsis: false,
        },
        {
             key: "remark",
             title: "备注",
             dataIndex: "remark",
             width: 120,
             ellipsis: false,
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    <a key='view' onClick={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                currentSalesOrder: record,
                                detailVisible: true,
                            }
                        });
                        }}>
                        查看
                    </a>,
                    <Divider type="vertical" key={'split1'}/>,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const defaultColumnsNew = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: SalesOrder) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleSave,
          }),
        };
      });

    const queryFormFields = [
        <div key='taskNoLike' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="任务单号"
                name={"taskNoLike"}
            >
                <Input
                    name="taskNoLike"
                />
            </Form.Item>
        </div>,
        <div key='customerNameLike' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="单位名称"
                name={"customerNameLike"}
            >
                <Input
                    name="customerNameLike"
                />
            </Form.Item>
        </div>,
        <div key='checkSn' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="待核销序号"
                name={"checkSn"}
            >
                <Input
                    name="checkSn"
                />
            </Form.Item>
        </div>,
        <div key='receiverName' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="联系人"
                name={"receiverName"}
            >
                <Input
                    name="receiverName"
                />
            </Form.Item>
        </div>,
        <div key='businessDate' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="开具日期"
                name={"businessDate"}
            >
                <Input
                    name="businessDate"
                />
            </Form.Item>
        </div>,
        <div key='finalTotalAmount' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="金额"
                name={"finalTotalAmount"}
            >
                <Input
                    name="finalTotalAmount"
                />
            </Form.Item>
        </div>,
        <div key='receiptOwnerId' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="开具人"
                name={"receiptOwnerId"}
            >
                <StaffSelect
                    name="receiptOwnerId"
                />
            </Form.Item>
        </div>,
        <div key='invoiceSn' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="发票号码"
                name={"invoiceSnLike"}
            >
                <Input
                    name="invoiceSnLike"
                />
            </Form.Item>
        </div>,
        <div key='receiptNo' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="缴款单号"
                name={"receiptNo"}
            >
                <Input
                    name="receiptNo"
                />
            </Form.Item>
        </div>,
        <div key='remarkLike' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="备注"
                name={"remarkLike"}
            >
                <Input
                    name="remarkLike"
                />
            </Form.Item>
        </div>,
        <div key='checkDateBegin' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="检验开始日期"
                name={"checkDateBegin"}
            >
                <DatePicker
                    name="checkDateBegin"
                />
            </Form.Item>
        </div>,
        <div key='checkDateEnd' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="检验结束日期"
                name={"checkDateEnd"}
            >
                <DatePicker
                    name="checkDateEnd"
                />
            </Form.Item>
        </div>,
        <div key='orgId' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="组织机构"
                name={"orgId"}
            >
                <OrganizationSelect
                    name="orgId"
                />
            </Form.Item>
        </div>,
    ];

    const reloadData = (params:object|undefined)=>{
        console.log(queryParams);

        if(queryParams?.checkDateBegin){
            queryParams.checkDateBegin = queryParams.checkDateBegin.startOf('month').format('YYYY-MM-DD') + " 00:00:00";
        }else {
            queryParams.checkDateBegin = undefined;
        }

        if(queryParams?.checkDateEnd){
            queryParams.checkDateEnd = queryParams.checkDateEnd.startOf('month').format('YYYY-MM-DD') + " 23:59:59";
        }else {
            queryParams.checkDateEnd = undefined;
        }

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
        };

        SalesOrderApi.findSalesOrderPage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });

            dispatch({
                type: 'updateState',
                payload: {
                    queryParams: newQueryParams,
                    pageData: pageInfo,
                }
            });
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const deleteSalesOrder = (salesOrder:SalesOrder)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定删除[" + salesOrder.receiptNo + "]吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await SalesOrderApi.deleteSalesOrder(salesOrder);

                reloadData({});
            }
        });
    }

    const sendMessage = (salesOrder:SalesOrder)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定发送短信[" + salesOrder.receiptNo + "]吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await SalesOrderApi.sendMessage(salesOrder);
                message.success("发送成功");
            }
        });
    }

    const unbindingSalesOrder = (salesOrder:SalesOrder)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定解绑订单[" + salesOrder.receiptNo + "]的费用及报告吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await SalesOrderApi.unbindingSalesOrder(salesOrder);

                reloadData({});
            }
        });
    }

    const batchDeleteSalesOrder = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "操作确认",
            content: "确定删除所选吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await SalesOrderApi.deleteSalesOrder(row?.id);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: SalesOrder
    }> = ({item}) => {

        const items = [];

        items.push({
            key: 'delete',
            label: '删除',
            disabled: item.code==='ADMIN'
        });

        items.push({
            key: 'unbinding',
            label: '解绑费用及报告',
            disabled: item.code==='ADMIN'
        });

        items.push({
            key: 'sendMessage',
            label: '重发短信',
            disabled: item.code==='ADMIN'
        });

        if(item.amountInfo.unpaidAmount && !item.confirmationLetterInfo?.confirmationLetterPrintedFlag){
            items.push({
                key: 'printXunzhenghan',
                label: '打印询证函',
            });
        }

        if(item.amountInfo.unpaidAmount && !item.lawyersLetterInfo?.lawyersLetterPrintedFlag){
            items.push({
                key: 'printLvshihan',
                label: '打印询证函',
            });
        }

        return (
            <Dropdown
                key="row-extra-btns"
                menu={{
                    onClick: ({key})=>{
                        if(key==='delete') {
                            deleteSalesOrder(item);
                        }else if(key=='unbinding') {
                            unbindingSalesOrder(item);
                        }else if(key=='sendMessage'){
                            sendMessage(item);
                        }else if(key=='printXunzhenghan') {
                            window.open(`/api/tenant/crm/sales-order/print_xunzhenghan/${item.id}?Authorization=${localStorage.getItem('Authorization')}`);
                            Modal.confirm({
                                title: '打印结果',
                                content: '打印成功？',
                                onOk: async () => {
                                    await SalesOrderApi.patchEditSalesOrder({
                                    id: item.id,
                                    confirmationLetterInfo: {
                                        confirmationLetterPrintedFlag: true,
                                    }
                                });
                                }
                            });

                        } else if(key=='printLvshihan') {
                            window.open(`/api/tenant/crm/sales-order/print_lvshihan/${item.id}?Authorization=${localStorage.getItem('Authorization')}`);
                            Modal.confirm({
                                title: '打印结果',
                                content: '打印成功？',
                                onOk: async () => {
                                    await SalesOrderApi.patchEditSalesOrder({
                                    id: item.id,
                                    lawyersLetterInfo: {
                                        lawyersLetterPrintedFlag: true,
                                    }
                                });
                                }
                            });
                        }
                    },
                    items: items,
                }}
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    useEffect(()=>{

        setColumns(defaultColumnsNew);
        reloadData({});
        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    useEffect(()=>{
        eventBus.removeAllListeners();
        
        eventBus.on("updateTableRow", ()=>{
            reloadData({});
        });
    }, [queryParams])

    const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <Form
                    ref={queryFormRef}
                    onValuesChange={(values)=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                queryParams: {
                                    ...queryParams,
                                    ...values,
                                }
                            }
                        });
                    }}
                >
                    <Space align="start" wrap>
                        { TableUtils.getQueryFields(queryFormFields, queryFormExpanded) }

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    dispatch({
                                        type: 'updateState',
                                        payload: {
                                            queryFormExpanded: !queryFormExpanded
                                        }
                                    });
                                }}
                            >
                                {queryFormExpanded?'收起':'展开'}{queryFormExpanded?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </Form>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    <Radio.Group onChange={
                        (e) => {
                            switch(e.target.value){
                                case '待支付':
                                    reloadData({
                                        payStatus: undefined,
                                        excludePayStatus: ['PAID'],
                                        excludeInvoiceStatus: undefined,
                                        balancedStatus: undefined,
                                        withInvoiceSnOrReceiptNoFlag: true,
                                        withReceiptNoFlag: undefined,
                                        withInvoiceSnFlag: undefined,
                                        uniSettlementFlag: undefined,
                                        finishedFlag: undefined,
                                    });
                                    break;
                                case '统一结算待结算':
                                    reloadData({
                                        payStatus: undefined,
                                        excludePayStatus: ['PAID'],
                                        excludeInvoiceStatus: undefined,
                                        balancedStatus: undefined,
                                        withInvoiceSnOrReceiptNoFlag: undefined,
                                        withReceiptNoFlag: undefined,
                                        withInvoiceSnFlag: undefined,
                                        uniSettlementFlag: true,
                                        finishedFlag: undefined,

                                    });
                                    break;
                                case '待开票': 
                                    reloadData({
                                        payStatus: ['PAID'],
                                        excludePayStatus: undefined,
                                        excludeInvoiceStatus: ['INVOICED'],
                                        balancedStatus: undefined,
                                        withInvoiceSnOrReceiptNoFlag: undefined,
                                        withReceiptNoFlag: true,
                                        withInvoiceSnFlag: false,
                                        uniSettlementFlag: undefined,
                                        finishedFlag: undefined,
                                    });
                                    break;
                                case '所有':
                                    reloadData({
                                        payStatus: undefined,
                                        excludePayStatus: undefined,
                                        excludeInvoiceStatus: undefined,
                                        balancedStatus: undefined,
                                        withInvoiceSnOrReceiptNoFlag: undefined,
                                        withReceiptNoFlag: undefined,
                                        withInvoiceSnFlag: undefined,
                                        uniSettlementFlag: undefined,
                                        finishedFlag: undefined,
                                    });
                                    break;
                                case '待平账':
                                    
                                    reloadData({
                                        payStatus: undefined,
                                        excludePayStatus: ['PAID'],
                                        excludeInvoiceStatus: undefined,
                                        balancedStatus: '待平账',
                                        withInvoiceSnOrReceiptNoFlag: undefined,
                                        withReceiptNoFlag: undefined,
                                        withInvoiceSnFlag: true,
                                        uniSettlementFlag: undefined,
                                        finishedFlag: undefined,

                                    });
                                    break;
                                case '可平账':
                                    reloadData({
                                        payStatus: ['PAID'],
                                        excludePayStatus: undefined,
                                        excludeInvoiceStatus: undefined,
                                        balancedStatus: '可平账',
                                        withInvoiceSnOrReceiptNoFlag: undefined,
                                        withReceiptNoFlag: undefined,
                                        withInvoiceSnFlag: true,
                                        uniSettlementFlag: undefined,
                                        finishedFlag: undefined,

                                    });
                                    break;
                                case '已完结':
                                        reloadData({
                                            payStatus: ['PAID'],
                                            excludePayStatus: undefined,
                                            excludeInvoiceStatus: undefined,
                                            balancedStatus: '已平账',
                                            withInvoiceSnOrReceiptNoFlag: undefined,
                                            withReceiptNoFlag: undefined,
                                            withInvoiceSnFlag: true,
                                            uniSettlementFlag: undefined,
                                            finishedFlag: true,
    
                                        });
                                        break;
                            }
                        }
                    }>
                        <Radio.Button value="待支付">待支付</Radio.Button>
                        <Radio.Button value="统一结算待结算">统一结算待结算</Radio.Button>
                        <Radio.Button value="待开票">待开票</Radio.Button>
                        <Radio.Button value="待平账">待平账</Radio.Button>
                        <Radio.Button value="可平账">可平账</Radio.Button>
                        <Radio.Button value="已完结">已完结</Radio.Button>
                        <Radio.Button value="所有">所有</Radio.Button>
                    </Radio.Group>
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        {
                            selectedRows.length>0 &&
                            <Button type="link">
                                已选择 <strong>{selectedRows.length}</strong> 条
                            </Button>
                        }

                        <Button
                            type="primary"
                            onClick={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        addFormVisible: true,
                                    }
                                });
                            }}
                        >
                            新建
                        </Button>
                        <Dropdown.Button
                            menu={{
                                onClick:({key})=>{
                                    if(key==='batchDelete'){
                                        batchDeleteSalesOrder();
                                    }else if(key==='batchExportExcel'){
                                        const newQueryParams = {
                                            ...queryParams,
                                            deletedFlag: false,
                                            Authorization: localStorage.getItem('Authorization')
                                        };
                                        window.open(`/api/tenant/crm/sales-order/excel?${stringify(newQueryParams)}`);
                                    }
                                },
                                items: [{
                                    key: 'batchDelete',
                                    label: '批量删除',
                                }, {
                                    key: 'batchExportExcel',
                                    label: '批量导出',
                                }]
                            }}
                        >
                            批量操作
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            bordered
                            dataSource={pageData?.list}
                            components={components}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                showTotal: (total)=>{
                                    return (
                                        <div>
                                            总共{total}条
                                        </div>
                                    );
                                },
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page,
                                        pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page,
                                        pageSize,
                                    });
                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: TableUtils.getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                            rowSelection={{
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                defaultSelectedRowKeys: [],
                                onChange:(selectedRowKeys, selectedRows)=>{
                                    dispatch({
                                        type: 'updateState',
                                        payload: {
                                            selectedRows,
                                        }
                                    });
                                }
                            }}
                        />
                    </Spin>
                </div>
            </div>

            {
                addFormVisible &&
                <AddSalesOrderForm
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                addFormVisible: false,
                            }
                        });
                        reloadData({});
                    }}
                />
            }

            {
                detailVisible &&
                <SalesOrderDetail
                    salesOrder={currentSalesOrder}
                    onClose={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                detailVisible: false,
                            }
                        });
                    }}
                    onSuccess={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                detailVisible: false,
                            }
                        });
                        reloadData({});
                    }}
                />
            }
        </div>
    );
};

export default SalesOrderPage;