import React, {FC, useState, useRef, useReducer, useEffect, useContext} from "react";

import {Table, Space, Modal, Dropdown, Menu, Button, FormInstance, Form, Input, Spin, Divider, message, Upload, Checkbox, Flex, InputNumber} from 'antd';
import type { GetRef, InputRef } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import {UpOutlined, DownOutlined, RedoOutlined} from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type'
import { Payment, PaymentDetailProps, PaymentQto } from './../../type/PaymentType';
import PaymentApi from "../../api/PaymentApi";
import { ColumnsType } from "antd/es/table";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ProFormText } from "@ant-design/pro-form";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import TableUtils from "./../../../../utils/ui/table/TableUtils"
import AddPaymentForm from "./PaymentAddForm";
import PaymentDetail from "./PaymentDetail";
import { SalesOrder, SalesOrderQto } from "../../type/SalesOrderType";
import SalesOrderApi from "../../api/SalesOrderApi";
import { render } from "@testing-library/react";
import PaymentConsumeLineApi from "../../api/PaymentConsumeLineApi";
import HttpInvoker from "@/utils/http/HttpInvoker";

type TableFormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
  }


const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  
  interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof SalesOrder;
    record: SalesOrder;
    handleSave: (record: SalesOrder) => void;
  }


  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
  
    const save = async () => {
      try {
        const values = await form.validateFields();
  
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
  
    let childNode = children;
  
    if (editable) {
        childNode = <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <InputNumber onPressEnter={save} onBlur={save} />
        </Form.Item>
    } 
  
    return <td {...restProps}>{childNode}</td>;
  };


interface PaymentState {
    addFormVisible: boolean,
    uploadFormVisible: boolean,
    detailVisible: boolean,
    queryFormExpanded: boolean,
    currentPayment: Payment,
    queryParams: PaymentQto,
    pageData: Pagination,
    spinning: boolean,
    selectedRows: Payment[],
    salesOrderPageData: Pagination,
    salesOrderQueryParams: SalesOrderQto
}

interface PaymentAction {
    type: string,
    payload: any,
}

const initPaymentState:PaymentState = {
    addFormVisible: false,
    uploadFormVisible: false,
    detailVisible: false,
    queryFormExpanded: true,
    currentPayment: {},
    queryParams: {},
    pageData: {},
    spinning: false,
    selectedRows: [],
    salesOrderPageData: {},
    salesOrderQueryParams: {},
}

const paymentReducer = (state:PaymentState, action:PaymentAction):PaymentState=>{

    switch(action.type) {
        case 'updateState':
            return {
                ...state,
                ...action.payload||{}
            };
        default:
            return state;
    }
}

const PaymentPage:React.FC = ()=>{

    const [paymentState, dispatch] = useReducer(paymentReducer, initPaymentState);

    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);
    const salesOrderQueryFormRef = useRef<FormInstance>(null);
    const [tableSize, setTableSize] = useState<SizeType>('middle'); 
    const [tableHeight, setTableHeight] = useState<number>(0); 
    const [columns, setColumns] = useState<ColumnsType<Payment>>([]);
    const [spinning, setSpinning] = useState<boolean>(false);
    const [rightSpinning, setRightSpinning] = useState<boolean>(false);
    const {addFormVisible, uploadFormVisible, detailVisible, queryFormExpanded, currentPayment,
        queryParams, pageData, selectedRows, salesOrderPageData, salesOrderQueryParams} = paymentState;

    const defaultColumns:ColumnsType<Payment> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
             key: "payer",
             title: "付款人",
             dataIndex: "payer",
             width: 100,
             ellipsis: false,
             render: (val)=>{
                return <a onClick={()=>{
                    reloadSalesOrderData({
                        customerName: val,
                    });
                }}>{val}</a>
             }
        },
        {
             key: "checkedAmount",
             title: "核销金额",
             dataIndex: "checkedAmount",
             width: 100,
             ellipsis: false,
        },
        {
             key: "uncheckAmount",
             title: "待核销金额",
             dataIndex: "uncheckAmount",
             width: 100,
             ellipsis: false,
             render: (val)=>{
                return <a onClick={()=>{
                    reloadSalesOrderData({
                        unpaidAmount: val,
                    });
                }}>{val}</a>
             },
        },
        {
             key: "remark",
             title: "备注",
             dataIndex: "remark",
             width: 100,
             ellipsis: false,
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    <a key='view' onClick={()=>{
                        dispatch({
                            type: 'updateState',
                            payload: {
                                currentPayment: record,
                                detailVisible: true,
                            }
                        });
                        }}>
                        查看
                    </a>,
                    <Divider type="vertical" key={'split1'}/>,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const salesOrderColumns = [
        {
            key: "orderType",
            title: "业务科室",
            dataIndex: "orderType",
            width: 100,
        },
        {
             key: "customerName",
             title: "报检单位",
             dataIndex: "customerName",
             width: 160,
             ellipsis: false,
        },
        {
             key: "unpaidAmount",
             title: "待支付金额",
             dataIndex: "amountInfo",
             width: 120,
             ellipsis: false,
             render:(val)=>{
                return val?.unpaidAmount
             }
        },
        {
             key: "checkedAmount",
             title: "核销金额",
             dataIndex: "checkedAmount",
             width: 120,
             editable: true,
        },
        {
             key: "op",
             title: "操作",
             dataIndex: "op",
             ellipsis: false,
             render:(val, record)=>{
                return <a onClick={async ()=>{

                     const confirmed =  await Modal.confirm({
                        content: '确定核销操作吗？'
                     });

                     if(!confirmed){
                        return;
                     }

                    await PaymentConsumeLineApi.addPaymentConsumeLine({
                        refSalesOrderId: record.id,
                        consumeAmount: record.checkedAmount,
                        paymentId: selectedRows[0].id,
                    });

                    reloadData({});
                    reloadSalesOrderData({});
                }}>核销</a>
             }
        },
        {
             key: "linkman",
             title: "联系人",
             dataIndex: "deliveryInfo",
             ellipsis: false,
             render: (val)=>{
                return val?.receiverName
             }
        },
        {
             key: "linkMobile",
             title: "联系电话",
             dataIndex: "deliveryInfo",
             ellipsis: false,
             render: (val)=>{
                return val?.receiverMobile
             }
        },
    ];

    const handleSave = (row: SalesOrder) => {
        const newData = [...salesOrderPageData.list];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        dispatch({
            type: 'updateState',
            payload: {
                salesOrderPageData: {
                    ...salesOrderPageData,
                    list: newData,
                }
            }
        });
      };

    const salesOrderColumnsNew = salesOrderColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: SalesOrder) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });

    const queryFormFields = [
        <div key='payerLike' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="缴款单位"
                name={"payerLike"}
            >
                <Input
                    name="payerLike"
                />
            </Form.Item>
        </div>,
         <div key='payAmount' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="缴款金额"
                name={"payAmount"}
            >
                <Input
                    name="payAmount"
                />
            </Form.Item>
        </div>,
         <div key='includeFlag' style={{display:'inline-block', height:40, width: 220}}>
            <Form.Item
                label="已核销"
                name={"includeFlag"}
            >
                <Checkbox name="includeFlag"/>
            </Form.Item>
        </div>,
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
            
        };

        PaymentApi.findPaymentPage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });

            dispatch({
                type: 'updateState',
                payload: {
                    queryParams: newQueryParams,
                    pageData: pageInfo,
                }
            });
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const reloadSalesOrderData = (params:object|undefined)=>{

        setRightSpinning(true);
        const newQueryParams = {
            ...salesOrderQueryParams,
            ...(params||{}),
            deletedFlag: false,
            payStatus: ['UNPAID', 'PAID_PART']
        };

        SalesOrderApi.findSalesOrderPage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });

            dispatch({
                type: 'updateState',
                payload: {
                    salesOrderQueryParams: newQueryParams,
                    salesOrderPageData: pageInfo,
                }
            });
        }).finally(()=>{
            setRightSpinning(false);
        });
    }


    const deletePayment = (payment:Payment)=>{
        Modal.confirm({
            title: "操作确认",
            content: "确定删除[" + payment.sn + "]吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await PaymentApi.deletePayment(payment);

                reloadData(queryParams);
            }
        });
    }

    const batchDeletePayment = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "操作确认",
            content: "确定删除所选吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await PaymentApi.deletePayment(row?.id);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: Payment
    }> = ({item}) => {

        const items = [];

        items.push({
            key: 'delete',
            label: '删除',
            disabled: item.code==='ADMIN'
        });

        return (
            <Dropdown
                key="row-extra-btns"
                menu={{
                    onClick: ({key})=>{
                        if(key==='delete') {
                            deletePayment(item);
                        }
                    },
                    items: items,
                }}
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };

    useEffect(()=>{
        setColumns(defaultColumns);
        reloadData({});
        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    return (
        <Flex vertical={false}>
            <div style={{width:'50%', height: '100%'}}>
                <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
                    <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                        <Form
                            ref={queryFormRef}
                            onValuesChange={(values)=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        queryParams: {
                                            ...queryParams,
                                            ...values,
                                        }
                                    }
                                });
                            }}
                        >
                            <Space align="start" wrap>
                                { TableUtils.getQueryFields(queryFormFields, queryFormExpanded) }

                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        reloadData({});
                                    }}
                                >
                                    查询
                                </Button>

                                <Button
                                    type="primary"
                                    danger
                                    onClick={()=>{
                                        queryFormRef.current?.resetFields();
                                    }}
                                >
                                    重置
                                </Button>

                                {
                                    queryFormFields.length>2 &&
                                    <Button
                                        type="link"
                                        onClick={()=>{
                                            dispatch({
                                                type: 'updateState',
                                                payload: {
                                                    queryFormExpanded: !queryFormExpanded,
                                                }
                                            });
                                        }}
                                    >
                                        {queryFormExpanded?'收起':'展开'}{queryFormExpanded?<UpOutlined />:<DownOutlined />}
                                    </Button>
                                }
                            </Space>
                        </Form>
                    </div>

                    <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                        <div style={{fontWeight:'bold', display:'inline-block'}}>
                            列表
                        </div>
                        <div style={{display: 'inline-block', float: 'right'}}>
                            <Space size={16}>
                                {
                                    selectedRows.length>0 &&
                                    <Button type="link">
                                        已选择 <strong>{selectedRows.length}</strong> 条
                                    </Button>
                                }

                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        dispatch({
                                            type: 'updateState',
                                            payload: {
                                                addFormVisible: true,
                                            }
                                        });
                                    }}
                                >
                                    新建
                                </Button> 
                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        dispatch({
                                            type: 'updateState',
                                            payload: {
                                                uploadFormVisible: true,
                                            }
                                        });
                                    }}
                                >
                                    上传Excel表格
                                </Button>
                                <Dropdown.Button
                                    menu={{
                                        onClick:async ({key})=>{
                                            if(key==='batchDelete'){
                                                batchDeletePayment();
                                            }else if(key=='exportUncheck'){
                                                window.open("/api/tenant/crm/payment/uncheck-excel?Authorization=" + localStorage.getItem('Authorization')||"");
                                            }else if(key=='deleteUncheck'){
                                                await HttpInvoker.deleteObject("/api/tenant/crm/payment/uncheck", {});
                                                reloadData({});
                                            }
                                        },
                                        items: [{
                                            key: 'exportUncheck',
                                            label: '导出未核销单',
                                        }, {
                                            key: 'deleteUncheck',
                                            label: '删除未核销单',
                                        }, {
                                            key: 'batchDelete',
                                            label: '批量删除',
                                        }]
                                    }}
                                >
                                    批量操作
                                </Dropdown.Button>
                                <RedoOutlined
                                    style={{fontSize:16, cursor:'pointer'}}
                                    onClick={()=>{
                                        reloadData({});
                                    }}
                                />
                                <TableSizeSetting
                                    onChange={(val)=>{
                                        setTableSize(val)
                                    }}
                                />
                                <TableColumnSetting
                                    columns={columns}
                                    onChange={(value)=>{
                                        setColumns(value)
                                    }}
                                />
                            </Space>
                        </div>
                    </div>
                    <div style={{flex:1, position:'relative'}}>
                        <div
                            ref={container}
                            style={{width: '100%', height:'100%', position:'absolute'}}
                        >
                            <Spin spinning={spinning}>
                                <Table
                                    
                                    bordered
                                    dataSource={pageData?.list}
                                    pagination={{
                                        current: queryParams?.page||1,
                                        pageSize: queryParams?.pageSize||10,
                                        total: pageData?.total,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        showQuickJumper: true,
                                        showSizeChanger: true,
                                        showTotal: (total)=>{
                                            return (
                                                <div>
                                                    总共{total}条
                                                </div>
                                            );
                                        },
                                        onChange:(page, pageSize)=>{
                                            reloadData({
                                                page,
                                                pageSize,
                                            });
                                        },
                                        onShowSizeChange:(page, pageSize)=>{
                                            reloadData({
                                                page,
                                                pageSize,
                                            });
                                        }
                                    }}
                                    size={tableSize}
                                    rowKey="id"
                                    columns={columns}
                                    scroll={{x: TableUtils.getTableScrollX(columns), y: TableUtils.getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                                    rowSelection={{
                                        type: 'radio',
                                        defaultSelectedRowKeys: [],
                                        onChange:(selectedRowKeys, selectedRows)=>{
                                            dispatch({
                                                type: 'updateState',
                                                payload: {
                                                    selectedRows,
                                                }
                                            });
                                        }
                                    }}
                                />
                            </Spin>
                        </div>
                    </div>

                    {
                        addFormVisible &&
                        <AddPaymentForm
                            onClose={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        addFormVisible: false,
                                    }
                                });
                            }}
                            onSuccess={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        addFormVisible: false,
                                    }
                                });
                                reloadData({});
                            }}
                        />
                    }

                    {
                        detailVisible &&
                        <PaymentDetail
                            payment={currentPayment}
                            onClose={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        detailVisible: false,
                                    }
                                });
                            }}
                            onSuccess={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        detailVisible: false,
                                    }
                                });
                                reloadData({});
                            }}
                        />
                    }

                    {
                        uploadFormVisible &&
                        <Modal
                            title='上传Excel'
                            width={480}
                            cancelButtonProps={null}
                            open={uploadFormVisible}
                            onOk={()=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        uploadFormVisible: false,
                                    }
                                })
                                reloadData({});
                            }}
                        >
                            <Form>
                                <Form.Item
                                    name='excel'
                                    label='Excel文件'
                                >
                                    <Upload name="excel" action={`/api/tenant/crm/payment/excel?Authorization=${localStorage.getItem('Authorization')}`}>
                                        <Button>请选择Excel文件</Button>
                                    </Upload>
                                </Form.Item>
                            </Form>
                        </Modal>
                    }
                </div>
            </div>

            <div style={{width:"50%"}}>
                <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
                    <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                        <Form
                            ref={salesOrderQueryFormRef}
                            onValuesChange={(values)=>{
                                dispatch({
                                    type: 'updateState',
                                    payload: {
                                        salesOrderQueryParams: {
                                            ...salesOrderQueryParams,
                                            ...values,
                                        }
                                    }
                                });
                            }}
                        >
                            <Space align="start" wrap>
                                
                                <div key='customerName' style={{display:'inline-block', height:40, width: 220}}>
                                    <Form.Item
                                        label="报检单位"
                                        name={"customerNameLike"}
                                    >
                                        <Input
                                            name="customerNameLike"
                                        />
                                    </Form.Item>
                                </div>
                                <div key='unpaidAmount' style={{display:'inline-block', height:40, width: 220}}>
                                    <Form.Item
                                        label="待付金额"
                                        name={"unpaidAmount"}
                                    >
                                        <Input
                                            name="unpaidAmount"
                                        />
                                    </Form.Item>
                                </div>
                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        reloadSalesOrderData({});
                                    }}
                                >
                                    查询
                                </Button>

                                <Button
                                    type="primary"
                                    danger
                                    onClick={()=>{
                                        salesOrderQueryFormRef.current?.resetFields();
                                    }}
                                >
                                    重置
                                </Button>
                            </Space>

                            <Spin spinning={rightSpinning}>
                            <Table
                                components={components}
                                columns={salesOrderColumnsNew}
                                bordered
                                    dataSource={salesOrderPageData?.list}
                                    pagination={{
                                        current: salesOrderQueryParams?.page||1,
                                        pageSize: salesOrderQueryParams?.pageSize||10,
                                        total: salesOrderPageData?.total,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        showQuickJumper: true,
                                        showSizeChanger: true,
                                        onChange:(page, pageSize)=>{
                                            reloadSalesOrderData({
                                                page,
                                                pageSize,
                                            });
                                        },
                                        onShowSizeChange:(page, pageSize)=>{
                                            reloadSalesOrderData({
                                                page,
                                                pageSize,
                                            });
                                        }
                                    }}
                                    size={tableSize}
                                    rowKey="id"
                            />
                            </Spin>
                        </Form>
                    </div>
                </div>
            </div>
        </Flex>
    );
};

export default PaymentPage;