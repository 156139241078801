
import {ProductModel, ProductModelQto} from './../type/ProductModelType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProductModel = async (params:ProductModel)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product-model`, params);
};


const editProductModel = async (params:ProductModel)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product-model/${params.id}`, params);
};


const patchEditProductModel = async (params:ProductModel)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product-model/${params.id}`, params);
};


const deleteProductModel = async (params:ProductModel)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product-model/${params.id}`, params);
};


const findProductModelById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model/${params}`, params);
};

const findProductModelList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model/list`, params);
};

const findProductModelPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model/page`, params);
};



export default {
    addProductModel,
    editProductModel,
    patchEditProductModel,
    deleteProductModel,
    findProductModelById,
    findProductModelList,
    findProductModelPage,
};


