
import {Customer, CustomerQto} from './../type/CustomerType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addCustomer = async (params:Customer)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/customer`, params);
};


const editCustomer = async (params:Customer)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/customer/${params.id}`, params);
};


const patchEditCustomer = async (params:Customer)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/customer/${params.id}`, params);
};


const deleteCustomer = async (params:Customer)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/customer/${params.id}`, params);
};


const findCustomerById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/customer/${params}`, params);
};

const findCustomerList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/customer/list`, params);
};

const findCustomerPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/customer/page`, params);
};



export default {
    addCustomer,
    editCustomer,
    patchEditCustomer,
    deleteCustomer,
    findCustomerById,
    findCustomerList,
    findCustomerPage,
};


