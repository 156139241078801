import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select, TreeSelect } from "antd";
import PostApi from "../../api/PostApi";
import type { Post } from "../../type/PostType";
import { ProFormSelect, ProFormTreeSelect } from "@ant-design/pro-form";

const {TreeNode} = TreeSelect;

const PostSelector:React.FC<any> = (props)=>{

    const [treeData, setTreeData] = useState<Post[]>([]);

    useEffect(()=>{
        PostApi.findPostTree({
            deletedFlag: false,
            ...(props.params||{})
        }).then((treeList)=>{
            setTreeData(treeList);
        });
    });

    return (
        <TreeSelect
            treeData = {treeData}
            allowClear = {true}
            fieldProps={{
                fieldNames:{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                }
            }}
            {...props}
        >
        </TreeSelect>
    );
};

export default PostSelector;