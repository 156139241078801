import React from 'react';
import type { CSSProperties } from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Tabs, Space, Image, Popover } from 'antd';
import { LoginForm, ProFormText, ProFormCheckbox } from '@ant-design/pro-form';
import {
  UserOutlined,
  LockOutlined,
  WindowsOutlined,
  AppleOutlined,
  AndroidOutlined,
} from '@ant-design/icons';

import AuthenticationApi from '../../api/AuthenticationApi';

import './LoginPage.less';


export default () => {
  const navigate = useNavigate();

  return (
    <div className='loginPage'>
      
      <div className='pageContent'>
        <div className='left'>

        </div>

        <div className='right'>
          <div className='rightRow1'>

          </div>

          
          <div className='rightRow2'>
          <LoginForm
            contentStyle={{minWidth:420}}
            style={{backgroundColor:'white', padding:32, minWidth:420}}
                        title="系统登入"
                        subTitle="衢州市特种设备检验管理系统"
                        actions={
                            <Space>

                            </Space>
                        }
                        onFinish={async (values)=>{

                          const token = await AuthenticationApi.login(values);
                          localStorage.setItem("Authorization", token);
                          setTimeout(()=>{
                            navigate("/home");
                          }, 500);
                        }}
          >
                <ProFormText
                              name="username"
                              fieldProps={{
                                  size: 'large',
                                  prefix: <UserOutlined className={'prefixIcon'} />,
                              }}
                              placeholder={'请输入用户名'}
                              rules={[
                                  {
                                  required: true,
                                  message: '用户名不能为空',
                                  },
                              ]}
                            />
                            <ProFormText.Password
                              name="password"
                              fieldProps={{
                                  size: 'large',
                                  prefix: <LockOutlined className={'prefixIcon'} />,
                              }}
                              placeholder={'请输入密码'}
                              rules={[
                                  {
                                  required: true,
                                  message: '密码不能为空',
                                  },
                              ]}
                            />
            </LoginForm>
          </div>

          <div  className='rightRow3'>

          </div>
        </div>
      </div>
      
      <div className='pageFooter'>
        版权所有：浙江鸿正软件科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QQ：1065809955
      </div>
    </div>
  );
};