import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, message, Row, Space } from "antd";
import React, { useRef } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import ProductReportApi from "../../api/ProductReportApi";
import { ProductReportAddFormProps, ProductReport } from "../../type/ProductReportType";

const ProductReportAddForm:React.FC<ProductReportAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:ProductReport = formRef?.current?.getFieldsValue();
                                    const ProductReport = await ProductReportApi.addProductReport(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(ProductReport);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="产品代码"
                            name={['refProductCode']}
                            rules={[{required:false, message: "产品代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="报告日期"
                            name={['reportDate']}
                            rules={[{required:false, message: "报告日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="任务单编号"
                            name={['taskNo']}
                            rules={[{required:true, message: "任务单编号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="报告编号"
                            name={['reportSn']}
                            rules={[{required:true, message: "报告编号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="电子报告文件地址"
                            name={['reportFileUrl']}
                            rules={[{required:true, message: "电子报告文件地址不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default ProductReportAddForm;