import { Select } from "antd";
import React, { useEffect, useState } from "react";
import OrganizationApi from "../../api/OrganizationApi";
import { Organization } from "../../type/OrganizationType";

const OrganizationSelect:React.FC<any> = (props)=>{

    const [options, setOptions] = useState<[]>([]);
    
    useEffect(()=>{
        OrganizationApi.findOrganizationList({
            limit: 1000,
            deletedFlag: false,
            ...(props.params||{})
        }).then((result)=>{
            const newOptions = result.map((item:Organization)=>{
                return {
                    key: item.id,
                    value: item.id,
                    label: item.name,
                }
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <Select
            options={options}
            allowClear={true}
            {...props}
        />
    );
}

export default OrganizationSelect;