import React, { Component, useState, useEffect, useContext, createContext, Children, useReducer } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import {Button, ConfigProvider, Dropdown, Layout, Menu, Tabs} from 'antd';
import {LoginOutlined} from '@ant-design/icons';
import routeList from '../../../../config/RouterList';


import './HomePage.less';
import StaffPositionAuthorizedResourceApi from '../../api/StaffPositionAuthorizedResourceApi';
import DictItemApi from '../../../system/api/DictItemApi';
import { DictItem } from '../../../system/type/DictItemType';

const parseMenus = (items:any)=>{
    if(!items){
        return null;
    }

    const menus = items.map((i:any)=>({
        label: i.name,
        title: i.name,
        key: i.id,
        children: parseMenus(i.children)
    }));

    return menus;
}

const findMenuById:any = (id:any, menus:any)=>{

    if(menus==null){
        return null;
    }

    let menu = null;

    for(let i=0; i<menus.length; i++){
        if(menus[i].id===id){
            return menus[i];
        }

        menu = findMenuById(id, menus[i].children);

        if(menu!=null){
            return menu;
        }
    }

    return null;
}

interface GlobalState {
    staffPosition?:any,
    menus:[],
    dictEntryMap:{
        [propName: string]: any;
    },
    dictEntryList:{
        [propName: string]: any;
    },
    [propName: string]: any;
}

interface GlobalAction {
    type: string,
    payload: any
}

const initGlobalState:GlobalState= {
    staffPosition: {},
    dictEntryMap: {},
    dictEntryList: [],
    menus: [],
};

const globalReducer = (globalState:GlobalState, action:GlobalAction):GlobalState=>{

    switch(action.type){
        case 'update':
            return action.payload;
        default:
            return globalState;
    }
}

const HomeContext = React.createContext(initGlobalState);

export default ()=>{

    const [globalState, globalDispatch] = useReducer(globalReducer, initGlobalState);
    const navigate = useNavigate();
    const location = useLocation();
    const [accessToken, setAccessToken] = useState<string|null>();
    const {menus, staffPosition} = globalState;
    const [tabKeyList, setTabKeyList] = useState<any>([]);
    const [activeTabKey, setActiveTabKey] = useState<string>('/home');
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);

    const token = localStorage.getItem("Authorization");

    if(token!=accessToken){
        setAccessToken(token);
    }
    
    useEffect(()=>{
    
        if(!token) {
            navigate("/login");
        }

        StaffPositionAuthorizedResourceApi.getCurrentPosition().then((staffPosition)=>{
            StaffPositionAuthorizedResourceApi.findAuthorizedMenuTree({}).then((menus)=>{
                DictItemApi.findDictItemList({
                    limit: 10000000,
                    deletedFlag: false,
                }).then((dictList:any)=>{
                    const dictEntryMap:{[propName:string]:any} = {};
                    const dictEntryList:{[propName:string]:any} = {};

                    (dictList||[]).forEach((item:DictItem)=>{
                        if(item.entryCode){
                            if(!dictEntryMap[item.entryCode]){
                                dictEntryMap[item.entryCode] = {};
                                dictEntryList[item.entryCode] = [];
                            }
                            dictEntryMap[item.entryCode][item.value] = item;
                            dictEntryList[item.entryCode].push(item);
                        }
                    });

                    globalDispatch({
                        type: 'update',
                        payload: {
                            menus,
                            staffPosition,
                            dictEntryList,
                            dictEntryMap,
                        }
                    });
                });
            });
        });
    }, [accessToken]);

    if((location.pathname==='' || location.pathname==='/' || location.pathname==='/home') && activeTabKey!=='/home') {
        setActiveTabKey('/home');
    } else if(tabKeyList.indexOf(location.pathname)<0){
        
        let hasPage = false;

        if(location.pathname=='/home' || location.pathname==='/'){
            hasPage = true;
        }else{
            routeList.forEach((i:any)=>{
                if(i.path==location.pathname){
                    hasPage = true;
                }
            });
        }

        if(!hasPage){
            navigate('/error/404');
        }else{
            tabKeyList.push(location.pathname);
            setTabKeyList([...tabKeyList]);
            setActiveTabKey(location.pathname);
        }
    } else if(location.pathname!==activeTabKey){
        setActiveTabKey(location.pathname);
    }

    return (
        <HomeContext.Provider value={globalState}>
            <div className='HomePage'>
                <Layout style={{width:'100%', height:'100%'}}>
                    <Layout.Header
                        className='HomeHeader'
                    >
                        <div className="LogoTitle">衢州市特种设备检验管理系统 1.0</div>

                        <div className='ExtraButton'>
                            <Dropdown
                                menu={{
                                    onClick:({key})=>{
                                        if(key==='logout') {
                                            localStorage.clear();
                                            navigate("/login");
                                        }
                                    },
                                    items: [{
                                        key: 'logout',
                                        icon: <LoginOutlined />,
                                        label: '注销'
                                    }]
                                }}
                            >
                                <a style={{color: 'white'}}>
                                    {staffPosition?.staff?.name || "匿名用户"}
                                </a>
                            </Dropdown>
                        </div>
                    </Layout.Header>

                    <Layout>
                        <Layout.Sider style={{backgroundColor: '#ffffff', overflow: 'auto'}}>
                            <Menu
                                mode="inline"
                                theme='light'
                                items={parseMenus(menus)}
                                inlineCollapsed={menuCollapsed}
                                onClick={({key})=>{
                                    const menu = findMenuById(key, menus);

                                    navigate(menu.linkPath)
                                }}
                            >

                            </Menu>
                        </Layout.Sider>
                        <Layout.Content>
                            <Tabs 
                                size='small'
                                type="editable-card" 
                                className="HomeContent"
                                tabBarStyle={{paddingTop:2}}
                                activeKey={activeTabKey}
                                hideAdd={true}
                                onChange={(activeKey)=>{
                                if(activeKey!==activeTabKey){
                                        navigate(activeKey);
                                }
                                }}
                                onEdit={(targetKey, action)=>{
                                    if(action==='remove'){
                                        const newTabKeyList = tabKeyList.filter((i:any)=>i!==targetKey);
                                        setTabKeyList(newTabKeyList);
                                        if(targetKey===activeTabKey){
                                            navigate('/home');
                                        }
                                    }
                                }}
                            >
                                <Tabs.TabPane 
                                    tab="首页" 
                                    tabKey="/home" 
                                    key="/home" 
                                    closable={false} 
                                    className='HomeContentTabBody'
                                >
                                    <div className='TabPaneBody'>
                                    </div>
                                </Tabs.TabPane>

                                {
                                    routeList.map(({
                                        path,
                                        title,
                                        component : Component
                                    }:{
                                        path: string,
                                        title: string,
                                        component: React.FC
                                    })=>{
                                        if(path==="/"){
                                            return null;
                                        }
                                        if(tabKeyList.indexOf(path)>=0){
                                            return (
                                                <Tabs.TabPane 
                                                    tab={title} 
                                                    tabKey={path} 
                                                    key={path} 
                                                    closable={true}
                                                    className='HomeContentTabBody'
                                                >
                                                    <div className='TabPaneBody'>
                                                        <Component />
                                                    </div>
                                                </Tabs.TabPane>
                                            );
                                        }
                                    })
                                }
                            </Tabs>
                        </Layout.Content>
                    </Layout>
                </Layout>
                
            </div>
        </HomeContext.Provider>
    );
}
