
import {SalesOrder, SalesOrderQto} from './../type/SalesOrderType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addSalesOrder = async (params:SalesOrder)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/sales-order`, params);
};


const editSalesOrder = async (params:SalesOrder)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/sales-order/${params.id}`, params);
};


const patchEditSalesOrder = async (params:SalesOrder)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/sales-order/${params.id}`, params);
};


const deleteSalesOrder = async (params:SalesOrder)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/sales-order/${params.id}`, {});
};

const unbindingSalesOrder = async (params:SalesOrder)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/sales-order/${params.id}/unbinding`, {});
};

const findSalesOrderById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sales-order/${params}`, {});
};

const findSalesOrderAddress = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sales-order/address`, {customerName: params});
};

const findSalesOrderList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sales-order/list`, params);
};

const findSalesOrderPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sales-order/page`, params);
};

const sendMessage = async (params:any)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/sales-order/${params.id}/message`, params);
};



export default {
    addSalesOrder,
    editSalesOrder,
    patchEditSalesOrder,
    deleteSalesOrder,
    findSalesOrderById,
    findSalesOrderAddress,
    findSalesOrderList,
    findSalesOrderPage,
    unbindingSalesOrder,
    sendMessage,
};


