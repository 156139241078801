import React from 'react';
import {useNavigate} from 'react-router-dom'
import { Result, Button } from 'antd';

export default () => {
    
    const navigate = useNavigate();

    return <Result
        status="403"
        title="403"
        style={{
            background: 'none',
        }}
        subTitle="对不起，服务器内部发生异常，请稍后重试或联系管理员报告问题！"
        extra={
            <Button type="primary" onClick={()=>{navigate('/home')}}>返回工作台</Button>
        }
    />
};