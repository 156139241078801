import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, message, Row, Space } from "antd";
import React, { useRef } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import CustomerApi from "../../api/CustomerApi";
import { CustomerAddFormProps, Customer } from "../../type/CustomerType";

const CustomerAddForm:React.FC<CustomerAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:Customer = formRef?.current?.getFieldsValue();
                                    const Customer = await CustomerApi.addCustomer(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(Customer);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="客户名称"
                            name={['name']}
                            rules={[{required:false, message: "客户名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户代码"
                            name={['code']}
                            rules={[{required:false, message: "客户代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户类型"
                            name={['type']}
                            rules={[{required:false, message: "客户类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name={['remark']}
                            rules={[{required:false, message: "备注不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="联系人"
                            name={['contactInfo','linkman']}
                            rules={[{required:false, message: "联系人不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="手机全号"
                            name={['contactInfo','linkmanMobile']}
                            rules={[{required:false, message: "手机全号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="固定电话"
                            name={['contactInfo','telephone']}
                            rules={[{required:false, message: "固定电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="传真号码"
                            name={['contactInfo','fax']}
                            rules={[{required:false, message: "传真号码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="电子邮箱"
                            name={['contactInfo','email']}
                            rules={[{required:false, message: "电子邮箱不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="门户网站"
                            name={['contactInfo','website']}
                            rules={[{required:false, message: "门户网站不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default CustomerAddForm;