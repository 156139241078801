
import {PaymentConsumeLine, PaymentConsumeLineQto} from './../type/PaymentConsumeLineType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addPaymentConsumeLine = async (params:PaymentConsumeLine)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/payment-consume-line`, params);
};


const editPaymentConsumeLine = async (params:PaymentConsumeLine)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/payment-consume-line/${params.id}`, params);
};


const patchEditPaymentConsumeLine = async (params:PaymentConsumeLine)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/payment-consume-line/${params.id}`, params);
};


const deletePaymentConsumeLine = async (params:PaymentConsumeLine)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/payment-consume-line/${params.id}`, params);
};


const findPaymentConsumeLineById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/payment-consume-line/${params}`, params);
};

const findPaymentConsumeLineList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/payment-consume-line/list`, params);
};

const findPaymentConsumeLinePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/payment-consume-line/page`, params);
};



export default {
    addPaymentConsumeLine,
    editPaymentConsumeLine,
    patchEditPaymentConsumeLine,
    deletePaymentConsumeLine,
    findPaymentConsumeLineById,
    findPaymentConsumeLineList,
    findPaymentConsumeLinePage,
};


