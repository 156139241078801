
import {ProductReport, ProductReportQto} from './../type/ProductReportType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProductReport = async (params:ProductReport)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product-report`, params);
};


const editProductReport = async (params:ProductReport)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product-report/${params.id}`, params);
};


const patchEditProductReport = async (params:ProductReport)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product-report/${params.id}`, params);
};


const deleteProductReport = async (params:ProductReport)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product-report/${params.id}`, params);
};


const findProductReportById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-report/${params}`, params);
};

const findProductReportList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-report/list`, params);
};

const findProductReportPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-report/page`, params);
};



export default {
    addProductReport,
    editProductReport,
    patchEditProductReport,
    deleteProductReport,
    findProductReportById,
    findProductReportList,
    findProductReportPage,
};


