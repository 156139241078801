import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, message, Row, Space } from "antd";
import React, { useRef } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import ProductModelPriceItemApi from "../../api/ProductModelPriceItemApi";
import { ProductModelPriceItemAddFormProps, ProductModelPriceItem } from "../../type/ProductModelPriceItemType";

const ProductModelPriceItemAddForm:React.FC<ProductModelPriceItemAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:ProductModelPriceItem = formRef?.current?.getFieldsValue();
                                    const ProductModelPriceItem = await ProductModelPriceItemApi.addProductModelPriceItem(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(ProductModelPriceItem);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="费用代码"
                            name={['code']}
                            rules={[{required:true, message: "费用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="费用名称"
                            name={['label']}
                            rules={[{required:true, message: "费用名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name={['remark']}
                            rules={[{required:false, message: "备注不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="显示顺序"
                            name={['sortNo']}
                            rules={[{required:false, message: "显示顺序不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default ProductModelPriceItemAddForm;