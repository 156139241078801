
import {Staff, StaffQto} from './../type/StaffType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addStaff = async (params:Staff)=>{

    return HttpInvoker.postObject(`/api/tenant/system/staff`, params);
};


const editStaff = async (params:Staff)=>{

    return HttpInvoker.putObject(`/api/tenant/system/staff/${params.id}`, params);
};


const patchEditStaff = async (params:Staff)=>{

    return HttpInvoker.patchObject(`/api/tenant/system/staff/${params.id}`, params);
};


const deleteStaff = async (params:Staff)=>{

    return HttpInvoker.deleteObject(`/api/tenant/system/staff/${params.id}`, params);
};


const findStaffById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/system/staff/${params}`, params);
};

const findStaffList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/staff/list`, params);
};

const findStaffPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/staff/page`, params);
};



export default {
    addStaff,
    editStaff,
    patchEditStaff,
    deleteStaff,
    findStaffById,
    findStaffList,
    findStaffPage,
};


