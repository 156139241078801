import Error403 from "./pages/error/Error403";
import Error404 from "./pages/error/Error404";
import Error500 from "./pages/error/Error500";


export default [{
    path: '/error/403',
    title: '禁止访问',
    component: Error403
}, {
    path: '/error/404',
    title: '页面不存在',
    component: Error404
}, {
    path: '/error/500',
    title: '系统错误',
    component: Error500
}]