import React from "react";
import { Dropdown } from "antd";
import { ColumnsType } from "antd/lib/table";


import {SettingOutlined} from '@ant-design/icons';

interface TableColumnSettingProps<T> {
    columns: ColumnsType<T>,
    onChange: (val:ColumnsType<T>)=>void
};

function  TableColumnSetting<T>(props:TableColumnSettingProps<T>){

    return (
        <Dropdown overlay={<div></div>} trigger={['click']}>
            <SettingOutlined 
                style={{fontSize:16}}
            />
        </Dropdown>
    );
};

export default TableColumnSetting;