import mainRouterList from './../modules/main/RouterList';
import systemRouterList from './../modules/system/RouterList';
import crmRouterList from './../modules/crm/RouterList';
import qztjRouterList from './../modules/qztj/RouterList';

export default [
    ...mainRouterList,
    ...systemRouterList,
    ...crmRouterList,
    ...qztjRouterList,
];