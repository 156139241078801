
import {ProductProp, ProductPropQto} from './../type/ProductPropType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProductProp= async (params:ProductProp)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product-prop`, params);
};


const editProductProp= async (params:ProductProp)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product-prop/${params.id}`, params);
};


const patchEditProductProp= async (params:ProductProp)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product-prop/${params.id}`, params);
};


const deleteProductProp= async (params:ProductProp)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product-prop/${params.id}`, params);
};


const findProductPropById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-prop/${params}`, params);
};

const findProductPropList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-prop/list`, params);
};

const findProductPropPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-prop/page`, params);
};



export default {
    addProductProp,
    editProductProp,
    patchEditProductProp,
    deleteProductProp,
    findProductPropById,
    findProductPropList,
    findProductPropPage,
};


