import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, message, Row, Space } from "antd";
import React, { useRef } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import SmsTextApi from "../../api/SmsTextApi";
import { SmsTextAddFormProps, SmsText } from "../../type/SmsTextType";

const SmsTextAddForm:React.FC<SmsTextAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:SmsText = formRef?.current?.getFieldsValue();
                                    const SmsText = await SmsTextApi.addSmsText(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(SmsText);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="业务标题"
                            name={['refTitle']}
                            rules={[{required:false, message: "业务标题不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="手机号"
                            name={['mobile']}
                            rules={[{required:false, message: "手机号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="短信内容"
                            name={['content']}
                            rules={[{required:false, message: "短信内容不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default SmsTextAddForm;