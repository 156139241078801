import OrganizationPage from "./pages/Organization";
import PostPage from "./pages/Post";
import RolePage from "./pages/Role";
import StaffPage from "./pages/Staff";
import StaffPositionPage from "./pages/StaffPosition";

export default [{
    path: '/system/organization',
    title: '组织机构管理',
    component: OrganizationPage
}, {
    path: '/system/post',
    title: '岗位管理',
    component: PostPage
},  {
    path: '/system/role',
    title: '角色管理',
    component: RolePage
}, {
    path: '/system/staff',
    title: '人员管理',
    component: StaffPage
},  {
    path: '/system/position',
    title: '岗位管理',
    component: StaffPositionPage
}, ];