
import HttpInvoker from "../../../utils/http/HttpInvoker";

const getCurrentPosition = async () => {
    return HttpInvoker.getObject('/api/tenant/system/staff-position-authorized-resource/current-staff-position', {
      method: 'GET',
    });
}
  
const findAuthorizedModuleList = (params:any) => {
    return HttpInvoker.getObject('/api/tenant/system/staff-position-authorized-resource/authorized-module-list', params);
}
  
  async function findAuthorizedMenuList(params:any) {
    return HttpInvoker.getObject('/api/tenant/system/staff-position-authorized-resource/authorized-menu-list', params);
  }
  
  async function findAuthorizedMenuTree(params:any) {
    return HttpInvoker.getObject('/api/tenant/system/staff-position-authorized-resource/authorized-menu-tree', params);
  }
  
  async function findAuthorizedPermissionList(params:any) {
    return HttpInvoker.getObject('/api/tenant/system/staff-position-authorized-resource/authorized-permission-list', params);
  }
  
  async function findAuthorizedPermissionTree(params:any) {
    return HttpInvoker.getObject('/api/tenant/system/staff-position-authorized-resource/authorized-permission-tree', params);
  }
  
  async function findAuthorizedOrganizationList(params:any) {
    return HttpInvoker.getObject('/api/tenant/system/staff-authorized-resource/authorized-organization/list', params);
  }
  
  async function findAuthorizedOrganizationTree(params:any) {
    return HttpInvoker.getObject('/api/tenant/system/staff-authorized-resource/authorized-organization/tree', params);
  }

export default {
    getCurrentPosition,
    findAuthorizedModuleList,
    findAuthorizedMenuList,
    findAuthorizedMenuTree,
    findAuthorizedPermissionList,
    findAuthorizedPermissionTree,
    findAuthorizedOrganizationList,
    findAuthorizedOrganizationTree
};