import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import zhCn from 'antd/locale/zh_CN';

import './index.css';
import HomePage from './modules/main/pages/home/HomePage';
import LoginPage from './modules/main/pages/login/LoginPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ConfigProvider
      locale={zhCn}
      componentSize={'middle'}
      autoInsertSpaceInButton={true}
      theme={{
        token: {
          borderRadius:2,
        }
      }}
    >
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<HomePage />} />
        </Routes>
      </Router>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
