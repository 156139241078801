import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import StaffApi from "../../api/StaffApi";
import { Staff, StaffDetailProps } from "../../type/StaffType";

const StaffDetail:React.FC<StaffDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
        width: 180,
        textAlign: 'right'
    };

    const [title, setTitle] = useState<string>(props?.staff?.name);
    const [edited, setEdited] = useState<boolean>(false);
    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Drawer
            title={title + "-详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {...props}
        >
            <ProDescriptions<Staff>
                bordered
                column={2}
                size={'middle'}
                request={async()=>{
                    const result:Staff = await StaffApi.findStaffById(props.staff?.id);
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    }
                }}
                editable={{
                    onSave: async (keyPath: any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const staff = await StaffApi.patchEditStaff(requestData);
                        setTitle(staff?.name);
                        setEdited(true);
                    }
                }}
            >
                <ProDescriptions.Item
                    label="工号"
                    labelStyle={labelStyle}
                    dataIndex={['jobNo']}
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="姓名"
                    labelStyle={labelStyle}
                    dataIndex={['name']}
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="性别"
                    labelStyle={labelStyle}
                    dataIndex={['sex']}
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="手机号"
                    labelStyle={labelStyle}
                    dataIndex={['mobile']}
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
                <ProDescriptions.Item
                    label="电子邮箱"
                    labelStyle={labelStyle}
                    dataIndex={['email']}
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom || "-"}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <Input />;
                    }}
                />
            </ProDescriptions>
        </Drawer>
    );
}

export default StaffDetail;