
import {ProductModelProp, ProductModelPropQto} from './../type/ProductModelPropType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProductModelProp = async (params:ProductModelProp)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product-model-prop`, params);
};


const editProductModelProp = async (params:ProductModelProp)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product-model-prop/${params.id}`, params);
};


const patchEditProductModelProp = async (params:ProductModelProp)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product-model-prop/${params.id}`, params);
};


const deleteProductModelProp = async (params:ProductModelProp)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product-model-prop/${params.id}`, params);
};


const findProductModelPropById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model-prop/${params}`, params);
};

const findProductModelPropList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model-prop/list`, params);
};

const findProductModelPropPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model-prop/page`, params);
};



export default {
    addProductModelProp,
    editProductModelProp,
    patchEditProductModelProp,
    deleteProductModelProp,
    findProductModelPropById,
    findProductModelPropList,
    findProductModelPropPage,
};


