import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Drawer, Form, FormInstance, Input, InputNumber, InputRef, message, Row, Select, Space, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import _ from 'lodash';
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import InvoiceApi from "../../api/InvoiceApi";
import { InvoiceAddFormProps, Invoice,  InvoiceLine} from "../../type/InvoiceType";


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: InvoiceLine;
    handleSave: (record: InvoiceLine) => void;
}
  
  
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
  
    const save = async () => {
        try {
            const values = await form.validateFields();
  
            handleSave({ ...record, ...values });
      } catch (errInfo) {
            console.log('Save failed:', errInfo);
      }
    };
  
    let childNode = children;

    let Component:any = Input;

    
  
    if (editable) {
        childNode = <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
                {
                    required: ['productName'].indexOf(dataIndex)>=0,
                    message: `${title}不能为空`,
                },
            ]}
        >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>

        if(['taxedUnitPrice', 'quantity'].indexOf(dataIndex)>=0){
            childNode = <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: ['taxedUnitPrice', 'quantity'].indexOf(dataIndex)>=0,
                        message: `${title}不能为空`,
                    },
                ]}
            >
                <InputNumber onPressEnter={save} onBlur={save} />
            </Form.Item>
        }else if('taxRate'==dataIndex) {
            childNode = <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: ['taxedUnitPrice', 'quantity'].indexOf(dataIndex)>=0,
                        message: `${title}不能为空`,
                    },
                ]}
            >
                <Select onBlur={save}>
                    <Select.Option value={3}>3%</Select.Option>
                    <Select.Option value={9}>9%</Select.Option>
                    <Select.Option value={13}>13%</Select.Option>
                    <Select.Option value={0}>免税</Select.Option>
                </Select>
            </Form.Item>
        }
    }
  
    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;



const InvoiceAddForm:React.FC<InvoiceAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);
    const [invoiceLines, setInvoiceLines] = useState<any[]>([]);

    const invoiceLinesColumns = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 50,
        },
        {
            key: "productName",
            title: "产品名称",
            dataIndex: "productName",
            width: 180,
            editable: true,
        },
        {
            key: "specs",
            title: "产品规格",
            dataIndex: "specs",
            width: 180,
            editable: true,
        },
        {
            key: "unity",
            title: "单位",
            dataIndex: "unity",
            width: 80,
            editable: true,
        },
        {
            key: "quantity",
            title: "数量",
            dataIndex: "quantity",
            width: 100,
            editable: true,
        },
        {
            key: "taxedUnitPrice",
            title: "含税单价",
            dataIndex: "taxedUnitPrice",
            width: 120,
            editable: true,
        },
        {
            key: "taxRate",
            title: "税率",
            dataIndex: "taxRate",
            width: 80,
            editable: true,
        },
        {
            key: "totalTaxedAmount",
            title: "含税总价",
            dataIndex: "totalTaxedAmount",
            width: 120,
            render: (val:any, rec:any)=>{
                return (rec.quantity||0)*(rec.taxedUnitPrice||0);
            }
        },
        {
            key: "actions",
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            render: (val:any, record:any) =>{
                return [
                    <a key='del-action' onClick={()=>{
                        const newData = _.pull(invoiceLines, record);
                        newData.forEach((item, idx)=>{
                            item.rowNo = idx+1;
                        });

                        setInvoiceLines([...newData]);
                    }}>
                        删除
                    </a>,
                ];
            }
        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    
    const handleSave = (row: InvoiceLine) => {
        const newData = [...invoiceLines];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setInvoiceLines(newData);
    };
    
    const getInvoiceLineFormValues = ()=>{

        const invoiceLineFormValues = invoiceLines.map((line)=>{
            delete line.rowNo;
            return line;
        });

        return invoiceLineFormValues;
    };

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:Invoice = formRef?.current?.getFieldsValue();

                                    values.lineList = getInvoiceLineFormValues();

                                    const Invoice = await InvoiceApi.addInvoice(values);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(Invoice);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="发票代码"
                            name={['code']}
                            rules={[{required:false, message: "发票代码不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="开票号码"
                            name={['sn']}
                            rules={[{required:false, message: "开票号码不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="开票日期"
                            name={['invoiceDate']}
                            rules={[{required:false, message: "开票日期不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户名称"
                            name={['buyerName']}
                            rules={[{required:false, message: "客户名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户统一信用代码"
                            name={['buyerCode']}
                            rules={[{required:false, message: "客户统一信用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户开户行"
                            name={['buyerBankName']}
                            rules={[{required:false, message: "客户开户行不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="购买方银行账号"
                            name={['buyerBankAccount']}
                            rules={[{required:false, message: "购买方银行账号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户地址"
                            name={['buyerAddress']}
                            rules={[{required:false, message: "客户地址不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户电话"
                            name={['buyerTelephone']}
                            rules={[{required:false, message: "客户电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售方名称"
                            name={['sellerName']}
                            rules={[{required:false, message: "销售方名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售方统一信用代码"
                            name={['sellerCode']}
                            rules={[{required:false, message: "销售方统一信用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售方开户行名称"
                            name={['sellerBankName']}
                            rules={[{required:false, message: "销售方开户行名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售方银行账号"
                            name={['sellerBankAccount']}
                            rules={[{required:false, message: "销售方银行账号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售方地址"
                            name={['sellerAddress']}
                            rules={[{required:false, message: "销售方地址不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="销售方电话"
                            name={['sellerTelephone']}
                            rules={[{required:false, message: "销售方电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Table 
                            bordered
                            pagination={false}
                            components={components}
                            columns={invoiceLinesColumns.map((col) => {
                                if (!col.editable) {
                                  return col;
                                }
                                return {
                                  ...col,
                                  onCell: (record: InvoiceLine) => ({
                                    record,
                                    editable: col.editable,
                                    dataIndex: col.dataIndex,
                                    title: col.title,
                                    handleSave,
                                  }),
                                };
                              })}
                            dataSource={invoiceLines}
                            footer={()=>{
                                return (
                                    <Button
                                        style={{width:'100%'}}
                                        onClick={()=>{
                                            invoiceLines.push({
                                                key: invoiceLines.length + 1 + "",
                                                rowNo: invoiceLines.length + 1
                                            });
                                            setInvoiceLines([...invoiceLines]);
                                        }}
                                    >
                                        <PlusOutlined /> 新增一行
                                    </Button>
                                );
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="总金额"
                            name={['totalAmount']}
                            rules={[{required:false, message: "总金额不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="总税额"
                            name={['totalTaxAmount']}
                            rules={[{required:false, message: "总税额不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="含税总金额"
                            name={['totalTaxedAmount']}
                            rules={[{required:false, message: "含税总金额不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name={['remark']}
                            rules={[{required:false, message: "备注不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="收款人"
                            name={['payee']}
                            rules={[{required:false, message: "收款人不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="审核人"
                            name={['reviewer']}
                            rules={[{required:false, message: "审核人不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="开票人"
                            name={['drawer']}
                            rules={[{required:false, message: "开票人不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default InvoiceAddForm;