
import {Invoice, InvoiceQto} from './../type/InvoiceType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addInvoice = async (params:Invoice)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/invoice`, params);
};


const editInvoice = async (params:Invoice)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/invoice/${params.id}`, params);
};


const patchEditInvoice = async (params:Invoice)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/invoice/${params.id}`, params);
};


const deleteInvoice = async (params:Invoice)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/invoice/${params.id}`, params);
};


const findInvoiceById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/invoice/${params}`, params);
};

const findInvoiceList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/invoice/list`, params);
};

const findInvoicePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/invoice/page`, params);
};



export default {
    addInvoice,
    editInvoice,
    patchEditInvoice,
    deleteInvoice,
    findInvoiceById,
    findInvoiceList,
    findInvoicePage,
};


