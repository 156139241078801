import { Button, Col, Descriptions, Drawer, Form, FormInstance, Input, InputNumber, message, Radio, Row, Select, Space, Table } from "antd";
import React, { useRef, useState } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import ProductApi from "../../api/ProductApi";
import { ProductAddFormProps, Product } from "../../type/ProductType";
import StaffSelect from "@/modules/system/pages/Staff/StaffSelector";
import SalesOrderApi from "../../api/SalesOrderApi";
import HttpInvoker from "@/utils/http/HttpInvoker";

const ProductAddForm:React.FC<ProductAddFormProps> = (props)=>{

    const formRef = useRef<FormInstance>(null);
    const {salesOrder} = props;
    const {businessCompany} = props;
    const [lineList, setLineList] = useState(salesOrder?.lineList);
    const [shippingAmount, setShippingAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    return (
        <Drawer
            title="新建"
            maskClosable={false}
            width={1200}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={props.onClose}
                >
                    关闭
                </Button>
            ]}
            footer={
                <div style={{textAlign:'right'}}>
                    <Space>
                        <Button 
                            type="primary"
                            onClick={async ()=>{
                                if(formRef?.current?.validateFields()){
                                    const values:Product = formRef?.current?.getFieldsValue();
                                    const formValues = {
                                        ...salesOrder,
                                        ...values,
                                        amountInfo: {
                                            totalAmount: totalAmount,
                                            shippingAmount: shippingAmount,
                                        },
                                        lineList: salesOrder.lineList.map(item=>{
                                            return {
                                                ...item,
                                            }
                                        })
                                    }
                                    const Product = await SalesOrderApi.addSalesOrder(formValues);
                                    formRef?.current?.resetFields();
                                    message.success("新建成功！");

                                    if(props.onSuccess){
                                        props.onSuccess(Product);
                                    }

                                    return true;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                props.onClose();
                            }}
                        >
                            取消
                        </Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <Form
                ref={formRef}
                initialValues={salesOrder}
                onValuesChange={async (changeValues)=>{
                    if(changeValues?.businessType && (salesOrder.orderType=='电梯' || salesOrder.orderType=='起重机械' || salesOrder.orderType=='叉车')){
                        let totalAmount = 0;

                        for(let i=0; i<lineList.length; i++){
                           const feeInfo = await HttpInvoker.getObject(`/api/tenant/crm/product/calculator`, {
                                productId: salesOrder.lineList[i]?.productId,
                                checkKind: changeValues['businessType'],
                            });
                            totalAmount += feeInfo.totalAmount;

                            lineList[i] = {
                                ...lineList[i],
                                totalAmount: feeInfo.totalAmount,
                                amountDetailList: feeInfo.lineList.map((feeItem=>{
                                    return {
                                        code : feeItem.code,
                                        label : feeItem.label,
                                        amount : feeItem.amount,
                                    }
                                }))
                            }
                        }

                        
                        setTotalAmount(totalAmount);
                        setLineList([...lineList]);
                    }

                    if(changeValues.tag){
                        let shippingAmount = 0;
                        
                        if(salesOrder.orderType=='叉车') {
                            const fee = lineList.length*50;

                            switch(changeValues.tag) {    
                                case '衢州市':
                                    shippingAmount=0;
                                    break;
                                case '江山市': 
                                    shippingAmount = fee<200?fee:200;
                                    break;
                                case '龙游县':
                                    shippingAmount = fee<200?fee:200;
                                    break;
                                case '常山县':
                                    shippingAmount = fee<200?fee:200;
                                    break;
                                case '开化县':
                                    shippingAmount = fee<250?fee:250;
                                    break;
                            }
                        }else {
                            switch(changeValues.tag) {
                                case '衢州市':
                                    shippingAmount=0;
                                    break;
                                case '江山市': 
                                    shippingAmount = 200;
                                    break;
                                case '龙游县':
                                    shippingAmount = 200;
                                    break;
                                case '常山县':
                                    shippingAmount = 200;
                                    break;
                                case '开化县':
                                    shippingAmount = 250;
                                    break;
                            }
                        }

                        setShippingAmount(shippingAmount);
                    }
                }}
            >
                <Row>
                    <Col span={24}>
                        <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', padding:8, marginBottom:8}}>
                            收费项目
                        </div>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="报检类型"
                            name={['businessType']}
                            rules={[{required:true, message: "报检类型"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Select onChange={(e)=>{
                                
                            }}>
                                {
                                    (salesOrder?.orderType=='电梯') &&
                                    [
                                        <Select.Option value="定期检验">定期检验</Select.Option>,
                                        <Select.Option value="安装检验">安装检验</Select.Option>,
                                        <Select.Option value="改造检验">改造检验</Select.Option>,
                                        <Select.Option value="重大修理检验">重大修理检验</Select.Option>
                                    ]
                                }
                                {
                                    (salesOrder?.orderType=='起重机械') &&
                                    [
                                        <Select.Option value="定期检验">定期检验</Select.Option>,
                                        <Select.Option value="首次检验">首次检验</Select.Option>,
                                        <Select.Option value="改造监检">改造监检</Select.Option>,
                                        <Select.Option value="重大修理监检">重大修理监检</Select.Option>,
                                        <Select.Option value="安装监检">安装监检</Select.Option>
                                    ]
                                }
                                {
                                    (salesOrder?.orderType=='锅炉') &&
                                    [
                                        <Select.Option value="内部检验">内部检验</Select.Option>,
                                        <Select.Option value="外部检验">外部检验</Select.Option>,
                                    ]
                                }
                                {
                                    (salesOrder?.orderType=='叉车') &&
                                    [
                                        <Select.Option value="首次检验">首次检验</Select.Option>,
                                        <Select.Option value="定期检验">定期检验</Select.Option>,
                                    ]
                                }
                                {
                                    (salesOrder?.orderType=='压力容器') &&
                                    [
                                        <Select.Option value="定期检验">定期检验</Select.Option>,
                                    ]
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="报检区域"
                            name={['tag']}
                            rules={[{required:true, message: "报检区域"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Select>
                                <Select.Option value="衢州市">衢州市</Select.Option>
                                <Select.Option value="江山市">江山市</Select.Option>
                                <Select.Option value="龙游县">龙游县</Select.Option>
                                <Select.Option value="开化县">开化县</Select.Option>
                                <Select.Option value="常山县">常山县</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', padding:8, marginBottom:8}}>
                            单位信息
                        </div>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="报检单位"
                            name={['customerName']}
                            rules={[{required:true, message: "报检单位"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>


                    <Col span={12}>
                        {/* <Form.Item
                            label="增值税号"
                            name={['customerCode']}
                            rules={[{required:false, message: "增值税号"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item> */}
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="接收人"
                            name={['deliveryInfo', 'receiverName']}
                            rules={[{required:true, message: "接收人"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="接收人电话"
                            name={['deliveryInfo', 'receiverMobile']}
                            rules={[{required:true, message: "接收人电话"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="联系地址"
                            name={['deliveryInfo', 'deliveryAddressDetail']}
                            rules={[{required:true, message: "联系地址"}]}
                            {...FormLayout.itemLayout.span24}
                        >
                            <Input />
                        </Form.Item>
                    </Col>


                    <Col span={24}>
                        <div style={{fontWeight: 'bolder', fontSize:18, backgroundColor: '#f5f5f5', padding:8, marginBottom:8}}>
                            费用信息
                        </div>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="缴款单号"
                            name={['receiptNo']}
                            rules={[{required:false, message: "缴款单号"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="缴款单开具人"
                            name={['receiptOwnerId']}
                            rules={[{required:false, message: "缴款单开具人"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <StaffSelect />
                        </Form.Item>
                    </Col>

                    {/* <Col span={12}>
                        <Form.Item
                            label="收费方式"
                            name={['amountInfo', 'payMethod']}
                            rules={[{required:false, message: "收费方式"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            
                            <Select>
                                <Select.Option value="POS">POS</Select.Option>
                                <Select.Option value="转账">转账</Select.Option>
                                <Select.Option value="现金">现金</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="收费金额"
                            name={['amountInfo', 'paidAmount']}
                            rules={[{required:false, message: "收费金额"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col> */}

                    {/* <Col span={12}>
                        <Form.Item
                            label="开票类型"
                            name={['invoiceInfo', 'invoiceType']}
                            rules={[{required:false, message: "开票类型"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Radio.Group>
                                <Radio value={'ELECTRONIC_NORMAL_INVOICE'}>普票</Radio>
                                <Radio value={'ELECTRONIC_SPECIAL_INVOICE'}>专票</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                    </Col>


                    <Col span={12}>
                        <Form.Item
                            label="开票单位名称"
                            name={['invoiceInfo', 'invoiceTitle']}
                            rules={[{required:false, message: "开票单位名称"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="纳税人识别号"
                            name={['invoiceInfo', 'invoiceCode']}
                            rules={[{required:false, message: "纳税人识别号"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="开户行"
                            name={['invoiceInfo', 'invoiceBankName']}
                            rules={[{required:false, message: "开票人开户行"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="银行账号"
                            name={['invoiceInfo', 'invoiceBankAccount']}
                            rules={[{required:false, message: "开票人银行账号"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="地址"
                            name={['invoiceInfo', 'invoiceAddress']}
                            rules={[{required:false, message: "开票人联系地址"}]}
                            {...FormLayout.itemLayout.span24}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="联系电话"
                            name={['invoiceInfo', 'invoiceTelephone']}
                            rules={[{required:false, message: "开票联系电话"}]}
                            {...FormLayout.itemLayout.span12}
                        >
                            <Input />
                        </Form.Item>
                    </Col> */}

                    <Col span={24}>
                        <Table
                            pagination={false}
                            dataSource={lineList}
                            summary={(pageData)=>{
                                return (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={6}>
                                            <div style={{textAlign:"right", fontWeight:"bolder"}}>
                                                设备数量：{salesOrder?.lineList?.length} 台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                路费：{shippingAmount}元&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                总价：{shippingAmount+ totalAmount}元</div>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );
                            }}
                            expandable={{
                                        expandedRowRender: (record:any) => {
                                            return [
                                                <Descriptions title="设备额外属性" column={4}>
                                                    {(record.extraPropList||[]).map((prop:any)=>{
                                                        return (
                                                            <Descriptions.Item label={prop['label']}>
                                                                {prop?.value}
                                                            </Descriptions.Item>
                                                        );
                                                    })}
                                                </Descriptions>,

                                                <Descriptions title="设备费用明细" column={4}>
                                                    {(record.amountDetailList||[]).map((prop:any)=>{
                                                        return (
                                                            <Descriptions.Item label={prop?.label}>
                                                                {prop?.amount}
                                                            </Descriptions.Item>
                                                        );
                                                    })}
                                                </Descriptions>
                                            ];
                                        },
                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                    }}
                            columns={[
                                {
                                    key: "rowNo",
                                    title: "序号",
                                    dataIndex: "rowNo",
                                    width: 70,
                                    fixed: 'left',
                                },
                                {
                                     key: "productName",
                                     title: "使用单位",
                                     dataIndex: "productName",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "productCode",
                                     title: "设备代码",
                                     dataIndex: "productCode",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "productName",
                                     title: "设备名称",
                                     dataIndex: "code",
                                     width: 100,
                                     ellipsis: true,
                                },
                                {
                                     key: "totalAmount",
                                     title: "费用",
                                     dataIndex: "totalAmount",
                                     width: 100,
                                     ellipsis: true,
                                },
                            ]}
                        >

                        </Table>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default ProductAddForm;