
import {ProductModelPriceItem, ProductModelPriceItemQto} from './../type/ProductModelPriceItemType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProductModelPriceItem = async (params:ProductModelPriceItem)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/product-model-price-item`, params);
};


const editProductModelPriceItem = async (params:ProductModelPriceItem)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/product-model-price-item/${params.id}`, params);
};


const patchEditProductModelPriceItem = async (params:ProductModelPriceItem)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/product-model-price-item/${params.id}`, params);
};


const deleteProductModelPriceItem = async (params:ProductModelPriceItem)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/product-model-price-item/${params.id}`, params);
};


const findProductModelPriceItemById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model-price-item/${params}`, params);
};

const findProductModelPriceItemList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model-price-item/list`, params);
};

const findProductModelPriceItemPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/product-model-price-item/page`, params);
};



export default {
    addProductModelPriceItem,
    editProductModelPriceItem,
    patchEditProductModelPriceItem,
    deleteProductModelPriceItem,
    findProductModelPriceItemById,
    findProductModelPriceItemList,
    findProductModelPriceItemPage,
};


