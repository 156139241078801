
import {SmsText, SmsTextQto} from './../type/SmsTextType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addSmsText = async (params:SmsText)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/sms-text`, params);
};


const editSmsText = async (params:SmsText)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/sms-text/${params.id}`, params);
};


const patchEditSmsText = async (params:SmsText)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/sms-text/${params.id}`, params);
};


const deleteSmsText = async (params:SmsText)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/sms-text/${params.id}`, params);
};


const findSmsTextById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sms-text/${params}`, params);
};

const findSmsTextList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sms-text/list`, params);
};

const findSmsTextPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/sms-text/page`, params);
};



export default {
    addSmsText,
    editSmsText,
    patchEditSmsText,
    deleteSmsText,
    findSmsTextById,
    findSmsTextList,
    findSmsTextPage,
};


