
import {Payment, PaymentQto} from './../type/PaymentType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addPayment = async (params:Payment)=>{

    return HttpInvoker.postObject(`/api/tenant/crm/payment`, params);
};


const editPayment = async (params:Payment)=>{

    return HttpInvoker.putObject(`/api/tenant/crm/payment/${params.id}`, params);
};


const patchEditPayment = async (params:Payment)=>{

    return HttpInvoker.patchObject(`/api/tenant/crm/payment/${params.id}`, params);
};


const deletePayment = async (params:Payment)=>{

    return HttpInvoker.deleteObject(`/api/tenant/crm/payment/${params.id}`, {});
};


const findPaymentById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/payment/${params}`, params);
};

const findPaymentList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/payment/list`, params);
};

const findPaymentPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/crm/payment/page`, params);
};



export default {
    addPayment,
    editPayment,
    patchEditPayment,
    deletePayment,
    findPaymentById,
    findPaymentList,
    findPaymentPage,
};


