import { SizeType } from "antd/es/config-provider/SizeContext";


const getTableScrollX = (columns: any) => {

    let xWidth = 0;

    for(let i=0; i<columns.length; i++){

        const column = columns[i];

        if(column.children!=null && column.children.length>0){
            xWidth += getTableScrollX(column.children);
        } else {
            if(column.width != null && typeof(column.width)==='number') {
                xWidth += column.width>0?column.width:120;
            } else {
                xWidth += 80;
            }
        }
    }

    return xWidth;
}


const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const getQueryFields = (queryFormFields:any[], queryFormExpanded:boolean):any[]=>{
    let endIdx = queryFormExpanded&&queryFormFields.length>2?queryFormFields.length:2;

    const fields:Element[] = [];

    for(let i=0; i<endIdx; i++){
        fields.push(queryFormFields[i])
    }

    return fields;
}

export default {

    getTableScrollX,
    getTableScrollY,
    getQueryFields,
}