import CustomerPage from "./pages/Customer";
import InvoicePage from "./pages/Invoice";
import PaymentPage from "./pages/Payment";
import ProductPage from "./pages/Product";
import ProductCategoryPage from "./pages/ProductCategory";
import ProductModelPage from "./pages/ProductModel";
import ProductReportPage from "./pages/ProductReport";
import SalesOrderPage from "./pages/SalesOrder";
import SmsTextPage from "./pages/SmsText";
import Plan from "./pages/Plan";

export default [{
    path: '/crm/customer',
    title: '客户信息维护',
    component: CustomerPage
}, {
    path: '/crm/product-model',
    title: '产品模型',
    component: ProductModelPage
}, {
    path: '/crm/product',
    title: '产品维护',
    component: ProductPage
}, {
    path: '/crm/invoice',
    title: '发票维护',
    component: InvoicePage
}, {
    path: '/crm/product-category',
    title: '产品类别',
    component: ProductCategoryPage
}, {
    path: '/crm/report',
    title: '产品报告',
    component: ProductReportPage
}, {
    path: '/crm/payment',
    title: '收款支付',
    component: PaymentPage
}, {
    path: '/crm/sms',
    title: '短信中心',
    component: SmsTextPage
},  {
    path: '/crm/plan',
    title: '报检中心',
    component: Plan
},  {
    path: '/crm/sales-order',
    title: '财务跟踪',
    component: SalesOrderPage
},];