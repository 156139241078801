import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import RoleApi from "../../api/RoleApi";
import { Role, RoleDetailProps } from "../../type/RoleType";

const RoleDetail:React.FC<RoleDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
        width: 180,
        textAlign: 'right'
    };

    const [title, setTitle] = useState<string>(props?.role?.name);
    const [edited, setEdited] = useState<boolean>(false);
    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Drawer
            title={title + "-详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {...props}
        >
            <ProDescriptions<Role>
                bordered
                column={2}
                size={'middle'}
                request={async()=>{
                    const result:Role = await RoleApi.findRoleById(props.role?.id);
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    }
                }}
                editable={{
                    onSave: async (keyPath: any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const role = await RoleApi.patchEditRole(requestData);
                        setTitle(role?.name);
                        setEdited(true);
                    }
                }}
            >
                <ProDescriptions.Item
                    label='角色编码'
                    labelStyle={labelStyle}
                    dataIndex={['code']}
                    valueType={'text'}
                    copyable={false}
                    render={(dom, entity, index, action)=>{
                        return (
                            <div>{entity?.code||"-"}</div>
                        );
                    }}
                    renderFormItem={()=>{
                        return <Input />
                    }}
                />
                <ProDescriptions.Item
                    label='角色名称'
                    labelStyle={labelStyle}
                    dataIndex={['name']}
                    valueType={'text'}
                    copyable={false}
                    render={(dom, entity, index, action)=>{
                        return (
                            <div>{entity?.name||"-"}</div>
                        );
                    }}
                    renderFormItem={()=>{
                        return <Input />
                    }}
                />
                <ProDescriptions.Item
                    label='角色简称'
                    labelStyle={labelStyle}
                    dataIndex={['nameAbbr']}
                    valueType={'text'}
                    copyable={false}
                    render={(dom, entity, index, action)=>{
                        return (
                            <div>{entity?.nameAbbr||"-"}</div>
                        );
                    }}
                    renderFormItem={()=>{
                        return <Input />
                    }}
                />
                <ProDescriptions.Item
                    label='角色别名'
                    labelStyle={labelStyle}
                    dataIndex={['nameAlias']}
                    valueType={'text'}
                    copyable={false}
                    render={(dom, entity, index, action)=>{
                        return (
                            <div>{entity?.nameAlias||"-"}</div>
                        );
                    }}
                    renderFormItem={()=>{
                        return <Input />
                    }}
                />
                <ProDescriptions.Item
                    label='显示顺序'
                    labelStyle={labelStyle}
                    dataIndex={['sortNo']}
                    valueType={'text'}
                    copyable={false}
                    render={(dom, entity, index, action)=>{
                        return (
                            <div>{entity?.sortNo||"-"}</div>
                        );
                    }}
                    renderFormItem={()=>{
                        return <InputNumber />
                    }}
                />
            </ProDescriptions>
        </Drawer>
    );
}

export default RoleDetail;