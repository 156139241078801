import ProDescriptions from "@ant-design/pro-descriptions";
import { Button, Descriptions, Drawer, Input, InputNumber, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import ProductModelApi from "../../api/ProductModelApi";
import { ProductModel, ProductModelDetailProps } from "../../type/ProductModelType";
import ProductModelPriceItemPage from "../ProductModelPriceItem";
import ProductModelPropPage from "../ProductModelProp";

const ProductModelDetail:React.FC<ProductModelDetailProps> = (props)=>{

    let labelStyle:React.CSSProperties = {
    };

    const [title, setTitle] = useState<string>(props?.productModel?.name);
    const [edited, setEdited] = useState<boolean>(false);
    const onClose = ()=>{
        if(edited && props.onSuccess) {
            props.onSuccess();
        } else if(!edited && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Drawer
            title={title + "-详情"}
            width={1200}   
            placement={'right'}
            maskClosable={false}
            closable={false}
            open={true}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {...props}
        >
            <Tabs>
                <Tabs.TabPane tab="基本信息" key={'basicInfo'}>
                    <ProDescriptions<ProductModel>
                        column={2}
                        size={'middle'}
                        request={async()=>{
                            const result:ProductModel = await ProductModelApi.findProductModelById(props.productModel?.id);
                            setTitle(result?.name);

                            return {
                                success: true,
                                data: result,
                            }
                        }}
                        editable={{
                            onSave: async (keyPath: any, newData:any, oldData:any)=>{
                                const requestData:any = {
                                    id: oldData?.id
                                };
                                let rTemp = requestData;
                                let nTemp = newData;

                                for(let i=0; i<keyPath.length; i++){
                                    let field = keyPath[i];

                                    if(i===keyPath.length-1){
                                        rTemp[field] = nTemp[field];
                                    }else{
                                        rTemp[field] = {};
                                        rTemp = rTemp[field];
                                        nTemp = nTemp[field];
                                    }
                                }

                                if(requestData['checkBeginTime']){
                                    requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                                }
                                if(requestData['checkEndTime']){
                                    requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                                }

                                const productModel = await ProductModelApi.patchEditProductModel(requestData);
                                setTitle(productModel?.name);
                                setEdited(true);
                            }
                        }}
                    >
                        <ProDescriptions.Item
                            label="模型代码"
                            labelStyle={labelStyle}
                            dataIndex={['code']}
                            valueType="text"
                            copyable={false}
                            render={(dom:any, entity:any, index:any, action:any) => {
                                return (
                                    <div>
                                        {dom || "-"}
                                    </div>
                                );
                            }}
                            renderFormItem={() => {
                                return <Input />;
                            }}
                        />
                        <ProDescriptions.Item
                            label="模型名称"
                            labelStyle={labelStyle}
                            dataIndex={['name']}
                            valueType="text"
                            copyable={false}
                            render={(dom:any, entity:any, index:any, action:any) => {
                                return (
                                    <div>
                                        {dom || "-"}
                                    </div>
                                );
                            }}
                            renderFormItem={() => {
                                return <Input />;
                            }}
                        />
                        <ProDescriptions.Item
                            label="备注"
                            labelStyle={labelStyle}
                            dataIndex={['remark']}
                            valueType="text"
                            copyable={false}
                            render={(dom:any, entity:any, index:any, action:any) => {
                                return (
                                    <div>
                                        {dom || "-"}
                                    </div>
                                );
                            }}
                            renderFormItem={() => {
                                return <Input />;
                            }}
                        />
                        <ProDescriptions.Item
                            label="显示顺序"
                            labelStyle={labelStyle}
                            dataIndex={['sortNo']}
                            valueType="text"
                            copyable={false}
                            render={(dom:any, entity:any, index:any, action:any) => {
                                return (
                                    <div>
                                        {dom || "-"}
                                    </div>
                                );
                            }}
                            renderFormItem={() => {
                                return <Input />;
                            }}
                        />
                    </ProDescriptions>
                </Tabs.TabPane>

                <Tabs.TabPane tab='属性列表' key={'props'}>
                    <ProductModelPropPage 
                        modelId={props.productModel?.id}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab='费用项目名称' key={'priceItem'}>
                    <ProductModelPriceItemPage />
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
}

export default ProductModelDetail;