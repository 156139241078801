import React from 'react';
import {useNavigate} from 'react-router-dom'
import { Result, Button } from 'antd';

export default () => {
    
    const navigate = useNavigate();

    return <Result
        status="403"
        title="403"
        style={{
            background: 'none',
        }}
        subTitle="对不起，您暂未获得权限访问此页面，请联系管理员！"
        extra={
            <Button type="primary" onClick={()=>{navigate('/home')}}>返回工作台</Button>
        }
    />
};